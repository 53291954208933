import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTable, useSortBy } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import Head from '../components/Head';
import Button from '../components/Core/Button';
import ContractModal from '../components/Modal/ContractModal';
import api from '../utils/api';
import apiv2 from '../utils/apiv2';
import { handleError } from '../utils/handleError';
import { htmlDecode } from '../utils/helper';
import { convertMsToHM } from '../utils/time';
import { labelRoles } from '../utils/constants';
import { updateJobPosition } from '../actions/jobAction';
// import { getJobHours } from './JobWallDetails';
import ConfirmHoursModal from '../components/EventDetails/ConfirmHoursModal';
import JobPositionDetailsForm from '../components/JobPosition/JobPositionDetailsForm';
import Loader from '../components/Core/Loader';
// import { getActiveContract } from '../actions/contractAction';
import 'moment/locale/de';
import { toast } from 'react-toastify';

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
      },
      useSortBy,
    );
  const { t, i18n } = useTranslation();

  // const disableButton = () => {
  //   buttonRef.current.disabled = true; // this disables the button
  // };

  return (
    <div className="table-responsive">
      <table
        className="table remove-space job-details-table"
        {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // without sorting
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'yourSalary') {
                    return (
                      <td {...cell.getCellProps()}>
                        {/*<span className="placeholder-text">
                          {t('event.totalExcl')}
                    </span>*/}
                        <span className="total-amount">
                          {cell.render('Cell')}
                        </span>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const JobDetails = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const userData = useSelector((state) => state.user);
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [briefingOneRead, setBriefingOneRead] = useState(false);
  const [briefingTwoRead, setBriefingTwoRead] = useState(false);
  const [showConfirmHoursModal, setShowConfirmHoursModal] = useState({
    visible: false,
    data: null,
  });
  // const jobPositionId = props.match.params.id;
  const { t } = useTranslation();
  const [activeContract, setActiveContract] = useState();
  const contractSection = useRef();
  const scrollToSection = () => {
    if (contractSection?.current) {
      contractSection.current.scrollIntoView();
    }
  };
  moment.locale(localStorage.getItem('i18nextLng') ?? 'de');

  async function fetchJobPosition() {
    const bookeduser = props.match.params.bookeduser
      ? props.match.params.bookeduser
      : userData.data.employee._id;
    setLoading(true);
    try {
      let response = await apiv2.get(`/job/${props.match.params.id}`);
      let signedContractID = false;
      const events = {
        ...response.data.data.jobs[0],
        jobs: response.data.data.jobs?.map((job) => {
          const jobPositions = job.jobPositions.map((jobPosition) => {
            if (
              jobPosition?.signedContract !== '' &&
              jobPosition?.contractID !== ''
            ) {
              signedContractID = jobPosition?.contractID;
            }
            let briefingsData = jobPosition.briefings;
            return { ...jobPosition, briefings: briefingsData };
          });
          return { ...job, jobPositions: jobPositions };
        }),
      };
      const allContracts = await api.get(
        `/contracts/getSignedContract/${signedContractID}`,
      );

      allContracts?.data?.data?.Contracts?.map((contract, index) => {
        if (contract?.contractID === signedContractID) {
          setActiveContract(contract);
        } else if (contract.status === true) {
          setActiveContract(contract);
        }
      });

      setEvent(events);
      const jobs = response.data.data.jobs;
      if (
        jobs &&
        jobs.length &&
        jobs[0].jobPositions.length &&
        jobs[0].jobPositions[0].readBriefings[0]
      ) {
        setBriefingOneRead(true);
      }
      if (
        jobs &&
        jobs.length &&
        jobs[0].jobPositions.length &&
        jobs[0].jobPositions[0].readBriefings[1]
      ) {
        setBriefingTwoRead(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      handleError(e);
    }
  }
  const renderbreifings = (url, type) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'Briefing-' + moment().format('DD.MM.YYYY') + '.' + `${type}`,
    );
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };
  const getBriefingsUrl = async (briefingspath) => {
    await api
      .post('jobs/fetchbriefingurl', {
        briefingsPath: briefingspath,
      })
      .then((res) => {
        // window.open(res.data.contracturl, '_blank');
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.briefingurl[0].data).buffer]),
        );
        renderbreifings(url, res.data.type);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  useEffect(() => {
    fetchJobPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.match?.params?.eventID,
    props?.match?.params?.id,
    props?.location?.state,
  ]);
  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
      },
      {
        Header: () => {
          return `${t('placeplaceholder')}`;
        },
        accessor: 'place',
      },
      {
        Header: () => {
          return `${t('startLabel')}`;
        },
        accessor: 'startTime',
      },
      {
        Header: () => {
          return `${t('endLabel')}`;
        },
        accessor: 'endTime',
      },
      {
        Header: () => {
          return `${t('profile.price')}`;
        },
        accessor: 'price',
      },
      {
        Header: () => {
          return `${t('requestOverview.travelCosts') + '*'}`;
        },
        accessor: 'travelCosts',
      },
      {
        Header: () => {
          return `${t('event.hours')}`;
        },
        accessor: 'hours',
      },
      {
        Header: () => {
          return `${t('myJob.salary') + '**'}`;
        },
        accessor: 'yourSalary',
      },
    ];
  }, []);

  const checkIfStaffIsBooked = (bookedStaff) => {
    if (
      userData &&
      userData.data &&
      userData.data.employee &&
      bookedStaff &&
      bookedStaff.employee
    ) {
      if (userData.data.employee._id === bookedStaff.employee._id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleBriefingRead = (newJobPosition, setState) => {
    delete newJobPosition.applications;
    delete newJobPosition.booked;
    let formData = new FormData();
    formData.append('fetchBriefing', true);

    Object.entries(newJobPosition).map((val) => {
      if (
        val[0] === 'location' ||
        val[0] === 'staffToBook' ||
        val[0] === 'bookedStaffDetails' ||
        val[0] === 'readyToBook' ||
        val[0] === 'rejectedApplicants' ||
        val[0] === 'contractId' ||
        val[0] === 'jobPlaceAddress' ||
        val[0] === 'employerData'
      ) {
        formData.append(val[0], JSON.stringify(val[1]));
      } else {
        formData.append(val[0], val[1]);
      }
    });
    dispatch(
      updateJobPosition({
        id: newJobPosition._id,
        payload: formData,
        callback: () => {
          setState(true);
        },
        failCallback: () => {},
      }),
    );
  };

  const renderTable = (positions) => {
    if (!positions || !positions.length) {
      return <></>;
    }

    const data = positions.map((position) => {
      return {
        date: moment(position.jobDate).format('dddd DD.MM.YYYY'),
        place: position.jobPlace,
        startTime: position.confirmJobStart
          ? moment(position.confirmJobStart).format('HH:mm')
          : moment(position.jobStart).format('HH:mm'),
        endTime: position.confirmJobEnd
          ? moment(position.confirmJobEnd).format('HH:mm')
          : moment(position.jobEnd).format('HH:mm'),
        price: position.confirmedJobCosts
          ? `€ ${Number(position.confirmedJobCosts).toFixed(2)}`
          : `€ ${Number(position.jobPrice).toFixed(2)}`,
        travelCosts: `€ ${Number(position.jobTravelCost).toFixed(2)}`,
        hours: `${convertMsToHM(
          (position?.confirmJobEnd
            ? position?.confirmJobEnd
            : position?.jobEnd) -
            (position?.confirmJobStart
              ? position?.confirmJobStart
              : position?.jobStart),
        )} `,
        yourSalary: `€ ${Number(
          position?.confirmJobTotalCost
            ? position?.confirmJobTotalCost
            : position?.jobTotalCost,
        ).toFixed(2)}`,
      };
    });

    return <Table columns={columns} data={data} />;
  };

  const renderPDFs = (jobs) => {
    if (
      jobs &&
      jobs.length &&
      jobs[0].jobPositions &&
      jobs[0].jobPositions.length &&
      checkIfStaffIsBooked(jobs[0].jobPositions[0].booked)
    ) {
      return (
        <>
          {jobs[0].jobPositions[0].briefings &&
          jobs[0].jobPositions[0].briefings.length &&
          jobs[0].jobPositions[0].briefings[0] ? (
            <div
              className={`small-boxs__item${
                briefingOneRead ? ' completed' : ''
              }`}
              onClick={() => {
                getBriefingsUrl(jobs[0].jobPositions[0].briefings[0]);
                let newJobPosition = {
                  ...jobs[0].jobPositions[0],
                };
                newJobPosition.readBriefings[0] = true;
                handleBriefingRead(newJobPosition, setBriefingOneRead);
              }}
              style={{ color: '#41a9c7' }}>
              1
            </div>
          ) : (
            <div className="small-boxs__item" style={{ cursor: 'default' }}>
              1
            </div>
          )}
          {jobs[0].jobPositions[0].briefings &&
          jobs[0].jobPositions[0].briefings.length &&
          jobs[0].jobPositions[0].briefings[1] ? (
            <div
              className={`small-boxs__item${
                briefingTwoRead ? ' completed' : ''
              }`}
              onClick={() => {
                getBriefingsUrl(event.jobs[0].jobPositions[0].briefings[1]);
                let newJobPosition = {
                  ...jobs[0].jobPositions[0],
                };
                newJobPosition.readBriefings[1] = true;
                handleBriefingRead(newJobPosition, setBriefingTwoRead);
              }}
              style={{ color: '#41a9c7' }}>
              2
            </div>
          ) : (
            <div className="small-boxs__item" style={{ cursor: 'default' }}>
              2
            </div>
          )}
        </>
      );
    } else {
      <>
        <div className="small-boxs__item">1</div>;
        <div className="small-boxs__item">2</div>;
      </>;
    }
  };

  return (
    <>
      {showConfirmHoursModal.visible && (
        <ConfirmHoursModal
          showConfirmHoursModal={showConfirmHoursModal}
          setShowConfirmHoursModal={setShowConfirmHoursModal}
          forEmployerSign={true}
          setEvent={setEvent}
          event={event}
          jobs={event.jobs}
        />
      )}
      <section className="content-section">
        <Head title={t('title.jobDetails')} />
        <Container>
          <div className="box-wrapper">
            {loading && <Loader />}
            {event && (
              <>
                <div className="form-back-wrapper">
                  <div
                    className="form-back"
                    onClick={() =>
                      history.push({
                        pathname: `/${labelRoles.employee}/my-jobs`,
                      })
                    }></div>
                  <div className="contact-btn-wrapper">
                    <p>{t('myjobsStaff.contact')}</p>
                    <Link to="/contact" className="btn">
                      {t('home.contact')}
                    </Link>
                  </div>
                </div>
                <h3>{t('profile.event') + ': ' + event.eventName}</h3>
                <span>
                  <p>
                    {t('jobdetails.eventinfo1')} {event.jobs[0].jobName}
                    {t('jobdetailsextra')}. <br />
                    {t('jobdetails.eventdetails2')} <br />
                    <br />
                    {t('jobdetails.confirmstep1')} <br />
                    {t('jobdetails.confirmstep2')} <br />
                    {t('jobdetails.confirmstep3')} <br />
                    {t('jobdetails.confirmstep4')} <br />
                    <br />
                    {t('jobdetails.eventdetails3')}
                  </p>
                </span>
                {event.jobs && event.jobs.length ? (
                  <>
                    <h3 className="headline-with-line">
                    {t('profile.jobPosition')}: {event.jobs[0].jobName}
                    </h3>
                    <Row>
                      <Col lg="6">
                        <p className="small-text text-uppercase text-semibold pb-3">
                          {t('requestOverview.description')}
                        </p>
                        <p className="small-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: htmlDecode(event.jobs[0].jobDescription),
                            }}
                          />
                        </p>
                      </Col>
                      <Col lg="6">
                        <p className="small-text text-uppercase text-semibold pb-3">
                          {t('requestOverview.staffRequirement')}
                        </p>
                        <p className="small-text">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: htmlDecode(event.jobs[0].jobRequirements),
                            }}
                          />
                        </p>
                      </Col>
                    </Row>
                    <h3 className="headline-with-line">
                      {t('Operational_data')}
                    </h3>
                    {renderTable(event.jobs[0].jobPositions)}
                  </>
                ) : (
                  <></>
                )}
                <p className="small-text placeholder-text ms-0">
                  {t('jobWallStaff.travelcoststext')}
                  <br />*{t('jobWallStaff.textOne')}
                  <br /> {t('jobWallStaff.otherdetail')}
                </p>
                {event?.jobs?.[0]?.jobPositions?.[0].isBookedUser ? (
                  <>
                    <div className="form-wrapper mt-5 pt-3">
                      <div className="ce-text">
                        <h3>{t('myjobsStaff.briefTitle')}</h3>
                        <p>{t('myjobsStaff.briefDesc')}</p>
                      </div>
                      <div className="small-boxs">{renderPDFs(event.jobs)}</div>
                    </div>
                    <JobPositionDetailsForm
                      jobPosition={event?.jobs?.[0]?.jobPositions?.[0]}
                      fetchJobPosition={fetchJobPosition}
                      scrollToSection={scrollToSection}
                      contractSection={contractSection}
                    />
                    <ContractModal
                      callbackToContractModal={() => {
                        fetchJobPosition();
                      }}
                      jobPosition={event?.jobs?.[0]?.jobPositions?.[0]}
                      companyName={event?.jobs?.[0]?.companyName}
                      checkIfStaffIsBooked={checkIfStaffIsBooked}
                      activeContract={activeContract}
                      scrollToSection={scrollToSection}
                    />{' '}
                  </>
                ) : null}
              </>
            )}
          </div>
          {event?.jobs?.[0]?.jobPositions?.[0].isBookedUser ? (
            <>
              <div className="box-wrapper">
                <Row className="align-items-end">
                  <Col md={7}>
                    <div className="ce-text">
                      <h3>{t('myjobsStaff.workHoursTitle')}</h3>
                      <p className="small-text">
                        {t('myjobsStaff.workHours')}
                        <br /> {t('myjobsStaff.workHoursDescTwo')}
                      </p>
                    </div>
                  </Col>
                  <Col md={5} className="text-end">
                    {event && (
                      <Button
                        disabled={
                          event.jobs[0]?.jobPositions[0]?.jobStart >
                          moment().valueOf()
                        }
                        className="btn event-btn"
                        onClick={() => {
                          history.push({
                            pathname: `/${labelRoles.employee}/confirm-job-hours/${props.match.params.id}/${props.match.params.eventID}`,
                          });
                        }}
                        label={
                          event.jobs[0]?.jobPositions[0]?.jobStart >
                          moment().valueOf()
                            ? `${t('available')} ${moment(
                                event.jobs[0].jobPositions[0].jobStart,
                              ).format('DD.MM.YYYY')}`
                            : `${t('myjobsStaff.btnOpen')}`
                        }
                      />
                    )}
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
        </Container>
      </section>
    </>
  );
};

export default JobDetails;
