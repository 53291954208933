import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, useSortBy } from 'react-table';
import moment from 'moment';
import Head from '../components/Head';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner, Container } from 'react-bootstrap';
import { getAllJobs } from '../actions/jobAction';
import { labelRoles, jobRegions } from '../utils/constants';
import api from '../utils/apiv2';

import { toast } from 'react-toastify';
import 'moment/locale/de';
import i18n from '../i18n';
import { convertMsToHM } from '../utils/time';

const Table = ({ columns, data }) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  // useEffect(() => {
  //   document.documentElement.scrollTo({
  //     top: -120,
  //     left: 0,
  //     behavior: 'instant',
  //   });
  // }, [location]);
  const onJobRowClick = (row, dataEventId) => {
    const location = {
      pathname: `/${labelRoles.employee}/job-wall-details/${row.original.id}/${dataEventId}`,
      state: {
        job: getRow(row.original.id),
      },
    };
    history.push(location);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
        initialState: {
          sortBy: [
            {
              id: 'date',
              desc: false,
            },
            {
              id: 'startTime',
              desc: false,
            },
            {
              id: 'endTime',
              desc: false,
            },
          ],
        },
      },
      useSortBy,
    );

  const getRow = (id) => {
    const row = data.filter((r) => r.id === id);
    return row[0];
  };

  return (
    <>
      <div className="table-responsive">
        {rows.length === 0 ? (
          <p>{t('jobWall.noJobs')}</p>
        ) : (
          <table
            className="table remove-space jab-wall-table"
            {...getTableProps()}
            key={shortid.generate()}>
            <thead key={shortid.generate()}>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={shortid.generate()}>
                  {headerGroup.headers.map((column) => (
                    // with sorting
                    <>
                      {column.id !== 'quantity' &&
                      column.id !== 'startTime' &&
                      column.id !== 'endTime' ? (
                        <th
                          {...column.getHeaderProps()}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          key={shortid.generate()}>
                          {column.render('Header')}
                          <span
                            className={`${
                              column.isSorted
                                ? column.isSortedDesc
                                  ? 'sort-arrow sort-arrow-descending'
                                  : 'sort-arrow sort-arrow-accending'
                                : 'sort-arrow'
                            }`}></span>
                        </th>
                      ) : (
                        <th
                          {...column.getHeaderProps()}
                          key={shortid.generate()}>
                          {column.render('Header')}
                        </th>
                      )}
                    </>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} key={shortid.generate()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={shortid.generate()}>
                    {row.cells.map((cell) => {
                      if (cell.column.id === 'jobPosition') {
                        return (
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              onJobRowClick(row, row?.original.eventID)
                            }
                            key={shortid.generate()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      } else if (cell.column.id === 'place') {
                        return (
                          <td {...cell.getCellProps()} key={shortid.generate()}>
                            <a
                              className="link"
                              href={`http://maps.google.com/?q=${cell.value}`}
                              target="_blank"
                              rel="noreferrer">
                              {cell.render('Cell')}
                            </a>
                          </td>
                        );
                      } else if (cell.column.id === 'eventExpirationDate') {
                        return (
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              onJobRowClick(row, row?.original.eventID)
                            }
                            key={shortid.generate()}>
                            <span>{cell.render('Cell')}</span>
                            <span className="edit-icon"></span>
                          </td>
                        );
                      } else if (cell.column.id === 'eventName') {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="jobs-event-name"
                            onClick={() =>
                              onJobRowClick(row, row?.original.eventID)
                            }
                            key={shortid.generate()}>
                            <span>{cell.render('Cell')}</span>
                          </td>
                        );
                      } else {
                        return (
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              onJobRowClick(row, row?.original.eventID)
                            }
                            key={shortid.generate()}>
                            <span>{cell.render('Cell')}</span>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

const getJobRow = (job) => {
  let jobPositions = [...job.jobPositions];

  if (jobPositions && jobPositions.length) {
    let pFlagArray = new Array(jobPositions.length).fill(false);
    let newJobPositions = [];
    for (let i = 0; i < jobPositions.length; i++) {
      jobPositions[i].duplicateIds = [];
      if (!pFlagArray[i]) {
        pFlagArray[i] = false;
        newJobPositions = [...newJobPositions, jobPositions[i]];
        for (let j = i + 1; j < jobPositions.length; j++) {
          if (
            jobPositions[i].jobPlace === jobPositions[j].jobPlace &&
            Math.abs(jobPositions[i].jobDate - jobPositions[j].jobDate) < 600 &&
            Math.abs(jobPositions[i].jobStart - jobPositions[j].jobStart) <
              600 &&
            Math.abs(jobPositions[i].jobEnd - jobPositions[j].jobEnd) < 600
          ) {
            jobPositions[i].duplicateIds.push(jobPositions[j]._id);
            pFlagArray[j] = true;
          }
        }
      }
    }
    const filteredJobPositions = newJobPositions.map((position) => {
      return {
        jobId: job._id,
        id: position._id,
        duplicateIds: position.duplicateIds,
        jobPosition: job.jobName,
        companyName: job.companyName,
        jobDescription: job.jobDescription,
        jobRequirements: job.jobRequirements,
        jobPrice: Number(position.jobPrice).toFixed(2),
        jobTotalCost: Number(position?.jobTotalCost).toFixed(2),
        hours: `${convertMsToHM(
          (position ? position.jobEnd : 0) - (position ? position.jobStart : 0),
        )} `,
        jobTravelCost: Number(position.jobTravelCost).toFixed(2),
        eventName: job.eventName,
        eventID: job.eventID,
        place: position.jobPlace,
        quantity: position.duplicateIds.length + 1,
        date: moment(position.jobDate).format('dddd DD.MM.yyyy'),
        date1: position.jobDate,
        startTime: moment(position.jobStart).format('HH:mm'),
        endTime: moment(position.jobEnd).format('HH:mm'),
        applyUntil: moment(job.eventExpirationDate).format('DD.MM.yyyy'),
      };
    });
    return filteredJobPositions;
  } else {
    return [];
  }
};

const JobWall = (props) => {
  const jobsData = useSelector((state) => state.jobs);
  const userData = useSelector((state) => state.user);
  const [jobs, setJobs] = useState([]);
  const dispatch = useDispatch();

  let regions = [];
  const { t } = useTranslation();
  const [regionToggled, setRegionToggled] = useState(false);
  const [jobTypeToggled, setJobTypeToggled] = useState(false);
  moment.locale(localStorage.getItem('i18nextLng') ?? 'de');
  const [eventJobsData, setEventJobsData] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const currEventId = urlParams.get('eventId');
  const [eventJobsLoading, setEventJobsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);
  useEffect(() => {
    if (jobs && jobs.length) {
      return true;
    }
    dispatch(getAllJobs());
  }, []);

  // Toggle switch filter
  useEffect(() => {
    async function filterjob() {
      let newJobs = [];
      let filteredData = [];
      if (
        currEventId &&
        currEventId !== null &&
        userData.data.active === true &&
        userData.data.employee.isApproved === true
      ) {
        try {
          setEventJobsLoading(true);
          const response = await api.get(`/job/by-event/${currEventId}`);
          let resJobs = await response?.data?.map((job) => {
            let newPositions = job?.jobPositions?.filter((position, key) => {
              if (position?.isBooked) {
                return false;
              }
              let rejectedStaff = position?.rejectedApplicants?.find(
                (app) => app?.applicant === userData?.data?.employee?._id,
              );
              let foundStaff = position?.applications?.find(
                (app) => app?.applicant === userData?.data?.employee?._id,
              );
              if (position?.isBookedUser || rejectedStaff || foundStaff?.applicant) {
                return false;
              } else {
                return true;
              }
            });
            return { ...job, jobPositions: newPositions };
          });

          setEventJobsData({ ...response?.data?.data?.event, jobs: resJobs });
          filteredData = resJobs;
        } catch (e) {
          toast.error(i18n.t('somethingwentwrong'));
        }
      } else {
        filteredData = jobsData.allJobs;
      }
      // region filter
      if (filteredData?.length > 0 && regionToggled === true) {
        let selectedJobRegions = [];

        jobRegions?.map((item) => {
          if (
            userData?.data?.employee?.preferedJobRegion?.includes(item?.value)
          ) {
            selectedJobRegions = [
              ...selectedJobRegions,
              item?.value,
              item?.label,
            ];
          }
        });

        filteredData?.map((job, index) => {
          let jobRegionFlag = false;
          let regionJobPositionFilter = [];
          job?.jobPositions?.map((jobPosition) => {
            const jobPositionState = jobPosition?.jobPlaceAddress?.state;
            let foundItems = selectedJobRegions?.filter((sjrItem) =>
              String(sjrItem)
                .toLowerCase()
                ?.includes(String(jobPositionState).toLowerCase()),
            );
            if (foundItems?.length > 0) {
              regionJobPositionFilter.push(jobPosition);
              jobRegionFlag = true;
            }
          });
          if (jobRegionFlag === true) {
            newJobs = [
              ...newJobs,
              { ...job, jobPositions: regionJobPositionFilter },
            ];
          }
        });
      }
      // job type filter
      if (filteredData?.length > 0 && jobTypeToggled === true) {
        let tempJobs = filteredData;
        if (regionToggled === true) {
          tempJobs = newJobs;
        }
        newJobs = tempJobs?.filter((job, index) =>
          userData?.data?.employee?.preferedJobs.includes(job?.jobName),
        );
      }
      if (regionToggled === true || jobTypeToggled === true) {
        setJobs(newJobs);
        setEventJobsLoading(false);
      } else {
        setJobs(filteredData);
        setEventJobsLoading(false);
      }
    }
    filterjob();
  }, [jobsData.allJobs, regionToggled, jobTypeToggled, currEventId]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('profile.jobPosition')}`;
        },
        accessor: 'jobPosition',
      },
      {
        Header: () => {
          return `${t('company')}`;
        },
        accessor: 'companyName',
      },
      {
        Header: () => {
          return `${t('event.name.staff')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('placeplaceholder')}`;
        },
        accessor: 'place',
      },
      {
        Header: () => {
          return `${t('quantityLabel')}`;
        },
        accessor: 'quantity',
      },
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
        sortType: (a, b) => {
          const timeA =
            moment(a.values.date, 'dddd DD.MM.yyyy').format('x') * 1;
          const timeB =
            moment(b.values.date, 'dddd DD.MM.yyyy').format('x') * 1;
          return timeA - timeB;
        },
      },
      {
        Header: () => {
          return `${t('startLabel')}`;
        },
        accessor: 'startTime',
      },
      {
        Header: () => {
          return `${t('endLabel')}`;
        },
        accessor: 'endTime',
      },
      {
        Header: () => {
          return `${t('apply.until')}`;
        },
        accessor: 'applyUntil',
        sortType: (a, b) => {
          const timeA =
            moment(a.values.applyUntil, 'DD.MM.yyyy').format('x') * 1;
          const timeB =
            moment(b.values.applyUntil, 'DD.MM.yyyy').format('x') * 1;
          return timeA - timeB;
        },
      },
    ];
  }, []);

  const renderTable = (jobs) => {
    let newJobs = [];
    jobs.map((job) => {
      const filteredJob = getJobRow(job);
      newJobs = [...newJobs, ...filteredJob];
      return false;
    });
    if (newJobs && newJobs.length) {
      Promise.all(
        newJobs.map((job) => {
          if (!regions.includes(job?.place?.split(',')[0])) {
            regions.push(job?.place?.split(',')[0]);
          }
        }),
      );
    }
    return <Table columns={columns} data={newJobs} />;
  };

  return (
    <section className="content-section">
      <Head title={`${t('jobWall')} | Superstaff`} />
      <Container>
        <div className="box-wrapper ">
          {currEventId && currEventId !== null && eventJobsData && (
            <div className="eventJobs">
              <p className="text-semibold m-0">
                {t('showingfromthisevents') + ' '}
                {eventJobsData?.jobs?.eventName}
              </p>
              <a className="link" href={`/${labelRoles.employee}/job-wall`}>
                {t('showjobsfromallevents')}
              </a>
            </div>
          )}
          <div className="toggle-switch-filters">
            <div className="toggle-switch-region">
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  name="regionFilter"
                  id="regionFilter"
                  checked={regionToggled}
                  onChange={() => {
                    setRegionToggled(!regionToggled);
                  }}
                />
                <span className="switch" />
              </label>
              <div className="filterValue">
                <p>{t('jobsinmyregion')}</p>
                <a
                  className="link text-decoration-none"
                  href={`/${labelRoles.employee}/profile`}
                  target="_blank"
                  rel="noreferrer">
                  {userData?.data?.employee?.preferedJobRegion
                    ?.slice(0, 2)
                    ?.join(', ')}
                  {userData?.data?.employee?.preferedJobRegion?.length > 2
                    ? ` +${
                        userData?.data?.employee?.preferedJobRegion?.length - 2
                      }`
                    : ''}
                </a>
              </div>
            </div>
            <div className="toggle-switch-region">
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  name="regionFilter"
                  id="regionFilter"
                  checked={jobTypeToggled}
                  onChange={() => {
                    setJobTypeToggled(!jobTypeToggled);
                  }}
                />
                <span className="switch" />
              </label>
              <div className="filterValue">
                <p>{t('Myjobtypesonly')}</p>
                <a
                  className="link text-decoration-none"
                  href={`/${labelRoles.employee}/profile`}
                  target="_blank"
                  rel="noreferrer">
                  {userData?.data?.employee?.preferedJobs
                    ?.slice(0, 2)
                    ?.join(', ')}
                  {userData?.data?.employee?.preferedJobs?.length > 2
                    ? ` +${userData?.data?.employee?.preferedJobs?.length - 2}`
                    : ''}
                </a>
              </div>
            </div>
          </div>
          {jobsData.loading || eventJobsLoading ? (
            <div className="d-flex align-items-center justify-content-center py-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('event.loading')}</span>
              </Spinner>
            </div>
          ) : (
            <>
              {jobs && jobs.length ? (
                <>{renderTable(jobs)}</>
              ) : (
                <div className="d-flex  py-4">
                  <h5>{t('jobWall.noJobsFound')}</h5>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
};

export default JobWall;
