import { eventConstants } from './types';
import { toast } from 'react-toastify';
import api from '../utils/api';
import apiv2 from '../utils/apiv2';
import i18n from '../i18n';

export const createEvent = ({ values, callback }) => {
  const request = () => {
    return { type: eventConstants.CREATE_EVENT_REQUEST };
  };
  const success = (event) => {
    return { type: eventConstants.CREATE_EVENT_SUCCESS, event };
  };
  const failure = (error) => {
    return { type: eventConstants.CREATE_EVENT_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const event = await apiv2.post('/event', { ...values });
      dispatch(success(event.data.data.event));
      if (callback) {
        callback();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
        return { success: false, message: error.response.data.message };
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
        return { success: false, message: i18n.t('somethingwentwrong') };
      }
    }
  };
};

export const fetchEvents = (
  { currPage, callback },
  refresh = false,
  pagination = true,
) => {
  const request = () => {
    return { type: eventConstants.GET_EVENTS_REQUEST };
  };
  const success = (data) => {
    return { type: eventConstants.GET_EVENTS_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: eventConstants.GET_EVENTS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const events = await api.get(
        `events/getUserEvents?page=${currPage}&pagination=${pagination}`,
      );
      dispatch(
        success({
          events: refresh
            ? [...events.data.data.events]
            : [
                ...getState().events.allEvents.events,
                ...events.data.data.events,
              ],
          pageCount: events.data.data.pageCount,
          currPage,
        }),
      );
      if (callback) {
        callback(events.data.data.events);
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const fetchEventsSpecific = ({ staff, callback }, refresh = false) => {
  const request = () => {
    return { type: eventConstants.GET_EVENTS_REQUEST };
  };
  const success = (data) => {
    return { type: eventConstants.GET_EVENTS_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: eventConstants.GET_EVENTS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const events = await api.get(
        `events/getUserEventsSpecific?staff=${staff}`,
      );
      dispatch(
        success({
          events: refresh
            ? [...events.data.data.events]
            : [
                ...getState().events.allEvents.events,
                ...events.data.data.events,
              ],
          pageCount: events.data.data.pageCount,
        }),
      );
      if (callback) {
        callback(events.data.data.events);
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const fetchCurrentEvents = ({ currPage, callback }, refresh = false) => {
  const request = () => {
    return { type: eventConstants.GET_CURRENT_EVENTS_REQUEST };
  };
  const success = (data) => {
    return { type: eventConstants.GET_CURRENT_EVENTS_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: eventConstants.GET_CURRENT_EVENTS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const events = await api.get(
        `events/getUserEvents?page=${currPage}&status=current`,
      );
      dispatch(
        success({
          events: refresh
            ? [...events.data.data.events]
            : [
                ...getState().events.currentEvents.events,
                ...events.data.data.events,
              ],
          pageCount: events.data.data.pageCount,
          currPage,
        }),
      );
      if (callback) {
        callback();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const fetchCompletedEvents = ({ currPage, callback }) => {
  const request = () => {
    return { type: eventConstants.GET_COMPLETED_EVENTS_REQUEST };
  };
  const success = (data) => {
    return { type: eventConstants.GET_COMPLETED_EVENTS_SUCCESS, data };
  };
  const failure = (error) => {
    return { type: eventConstants.GET_COMPLETED_EVENTS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const events = await api.get(
        `events/getUserEvents?page=${currPage}&status=completed`,
      );
      dispatch(
        success({
          events: [
            ...getState().events.completedEvents.events,
            ...events.data.data.events,
          ],
          pageCount: events.data.data.pageCount,
          currPage,
        }),
      );
      if (callback) {
        callback();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const updateEvent = ({ id, payload, callback }) => {
  const request = () => {
    return { type: eventConstants.UPDATE_EVENT_REQUEST };
  };
  const success = (events) => {
    return { type: eventConstants.UPDATE_EVENT_SUCCESS, events };
  };
  const failure = (error) => {
    return { type: eventConstants.UPDATE_EVENT_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const event = await api.patch(`events/updateEvent/${id}`, {
        ...payload,
      });
      const updatedEvent = event.data.data.event;
      const memoUpdateEvents = (sEvent) => {
        if (sEvent._id === updatedEvent._id) {
          return { ...updatedEvent };
        } else {
          return { ...sEvent };
        }
      };
      const newAllEvents =
        getState().events.allEvents.events.map(memoUpdateEvents);
      const newCompletedEvents = getState()
        .events.completedEvents.events.map(memoUpdateEvents)
        .filter((sEvent) => sEvent.eventExpirationDate < new Date().getTime());
      const newCurrentEvents = getState()
        .events.currentEvents.events.map(memoUpdateEvents)
        .filter((sEvent) => sEvent.eventExpirationDate > new Date().getTime());
      dispatch(
        success({
          allEvents: newAllEvents,
          completedEvents: newCompletedEvents,
          currentEvents: newCurrentEvents,
        }),
      );
      toast.success(i18n.t('eventupdated'));
      if (callback) {
        callback();
      }
    } catch (error) {
      if (callback) {
        callback();
      }
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

export const updateEventByEventID = ({ eventID, payload }) => {
  const request = () => {
    return { type: eventConstants.UPDATE_EVENT_REQUEST };
  };
  const success = (events) => {
    return { type: eventConstants.UPDATE_EVENT_SUCCESS, events };
  };
  const failure = (error) => {
    return { type: eventConstants.UPDATE_EVENT_FAILURE, error };
  };

  return async (dispatch, getState) => {
    // const events = getState().events;

    try {
      const memoUpdateEvents = (sEvent) => {
        if (sEvent._id === eventID) {
          return { ...sEvent, jobs: payload.jobs };
        } else {
          return { ...sEvent };
        }
      };
      const newAllEvents =
        getState().events.allEvents.events.map(memoUpdateEvents);
      const newCompletedEvents =
        getState().events.completedEvents.events.map(memoUpdateEvents);
      const newCurrentEvents =
        getState().events.currentEvents.events.map(memoUpdateEvents);
      dispatch(
        success({
          allEvents: newAllEvents,
          completedEvents: newCompletedEvents,
          currentEvents: newCurrentEvents,
        }),
      );
      // if (eventsList && eventsList.length) {
      //   const newEventsList = eventsList.map((event) => {
      //     if (event.eventID === eventID) {
      //       return {
      //         ...event,
      //         ...payload,
      //       };
      //     } else {
      //       return { ...event };
      //     }
      //   });
      //   dispatch(
      //     success({
      //       allEvents: newEventsList,
      //     }),
      //   );
      // }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure(i18n.t('somethingwentwrong')));
        toast.error(i18n.t('somethingwentwrong'));
      }
    }
  };
};

// GET Request to fetch all Events
export const getEvents = () => {
  const request = () => {
    return { type: eventConstants.GET_ALLEVENTS_REQUEST };
  };
  const success = (events) => {
    return { type: eventConstants.GET_ALLEVENTS_SUCCESS, data: events };
  };
  const failure = (error) => {
    return { type: eventConstants.GET_ALLEVENTS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const events = await api.get('/events');

      // let allEvents = getState()?.events?.allEvents?.events
      //   ? getState()?.events?.allEvents?.events
      //   : [];

      dispatch(
        success({
          events: [...events?.data?.data?.events],
          currPage: 1,
          pageCount: 1,
        }),
      );
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure('Something went wrong!'));
        toast.error('Something went wrong!');
      }
    }
  };
};
