import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../Core/Button';
import i18n from '../../i18n';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function CardForm({ onSubmit, setPaymentMethod, setShowFeeModal }) {
  const elements = useElements();
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setError(null);

    try {
      // Create Payment Method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        setError(error.message);
      } else {
        setPaymentMethod(paymentMethod.id);
        onSubmit();
        setShowFeeModal(false);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="payment-form">
      <div>
        <label>{i18n.t('eventFeePayment.modal.cardNumber')}</label>
        <div className="input-container number" id="card-number">
          <CardNumberElement />
        </div>
      </div>
      <div className="second-section">
        <div className="expiry">
          <label>{i18n.t('validThrough')}</label>
          <div className="input-container" id="card-expiry">
            <CardExpiryElement />
          </div>
        </div>
        <div className="cvc">
          <label>{i18n.t('CVV/CVC')}</label>
          <div className="input-container" id="card-cvc">
            <CardCvcElement />
          </div>
        </div>
      </div>

      {error && <div className="error-message">{error}</div>}
      <div className="btn-container">
        <Button
          className="btn btn-primary"
          label={i18n.t('imageModel.cancelSave')}
          type="submit"
          onClick={() => {
            setShowFeeModal(false);
          }}
        />
        <Button
          className="btn"
          label={i18n.t('eventFeePayment.modal.submitBtn')}
          type="submit"
          disabled={loading}
          onClick={handleFormSubmit}
        />
      </div>
    </form>
  );
}

function EventFeePayment({
  handleSubmit,
  showFeeModal,
  setShowFeeModal,
  setPaymentMethod,
  fee,
}) {
  return (
    <Modal
      className="event-fee-modal"
      show={showFeeModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="info-modal"
      onHide={() => {
        setShowFeeModal(false);
      }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3>{i18n.t('eventFeePayment.modal.headline')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{i18n.t('eventFeePayment.modal.thankYou')}</p>
        &nbsp;
        <p>
          {i18n.t('eventFeePayment.modal.description', {
            fee: `${fee?.toString()}€ ${i18n.t('eventFeePayment.modal.vat')}`,
          })}
        </p>
        &nbsp;
        <p>
          <b>{i18n.t('eventFeePayment.modal.rule')}</b>
        </p>
        <div className="divider">
          <hr />
        </div>
        <p id="payment-type-text">
          {i18n.t('eventFeePayment.modal.paymentType')}
        </p>
        <Elements stripe={stripePromise}>
          <CardForm
            onSubmit={handleSubmit}
            setPaymentMethod={setPaymentMethod}
            setShowFeeModal={setShowFeeModal}
          />
        </Elements>
      </Modal.Body>
    </Modal>
  );
}

export default EventFeePayment;
