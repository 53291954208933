import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// import RichTextEditor from 'react-rte';
import GlobalContext from '../../context/GlobalContext';
import Button from '../Core/Button';
import CustomSelect from '../Core/Select';
// import Textarea from '../Core/Textarea';
import JobPositionForm, { initialJobPosition } from '../JobPositionForm';
import { manageStartEndTime } from '../../utils/time';
import api from '../../utils/api';
import { handleError } from '../../utils/handleError';
import { fetchJobs } from '../../actions/settingAction';
import { createJob } from '../../actions/jobAction';
import { getTimeDifferent } from '../../utils/helper';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const bonusOptions = [
  { value: '0', label: 'None' },
  { value: '5', label: '5%' },
  { value: '10', label: '10%' },
  { value: '15', label: '15%' },
  { value: '20', label: '20%' },
  { value: '25', label: '25%' },
  { value: '30', label: '30%' },
  { value: '35', label: '35%' },
  { value: '40', label: '40%' },
  { value: '45', label: '45%' },
  { value: '50', label: '50%' },
];

const NewJob = () => {
  const dispatch = useDispatch();
  const [jobLoading, setJobLoading] = useState(false);
  // const settings = useSelector((state) => state.settings);
  const user = useSelector((state) => state.user && state.user.data);
  // const { jobs } = settings;
  const [jobPositions, setJobPositions] = useState([]);
  const { showNewJobModal, setShowNewJobModal } = useContext(GlobalContext);
  const [places, setPlaces] = useState([]);
  const [staffType, setStaffType] = useState([]);

  const fetchPlaces = async () => {
    try {
      let response = await api.get('/cities');
      let resPlaces = response.data.data.filteredCities;

      let jobTypeResponse = await api.get('/global-settings');
      let resJobTypes = jobTypeResponse.data.settings[0].staffTypes;
      setStaffType(resJobTypes);

      // setPlaceLoading(false);
      const filteredPlaces = resPlaces.map((city) => ({
        label: city,
        id: city,
      }));
      setPlaces(filteredPlaces);

      const filteredJobs = resJobTypes.map((job) => ({
        label: job.name,
        value: job.name,
      }));
      setJobPositions(filteredJobs);
    } catch (e) {
      // setPlaceLoading(false);
      handleError(e);
    }
  };
  const { t, i18n } = useTranslation();

  useEffect(() => {
    fetchPlaces();
  }, []);

  useEffect(() => {
    if (jobPositions && jobPositions.length) {
      return;
    }
    dispatch(fetchJobs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <Modal
      show={showNewJobModal.visible}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      onHide={() => setShowNewJobModal({ ...showNewJobModal, visible: false })}
      className="add-job-login-modal contract-modal">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('addJobLabel')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: '',
            description: '',
            requirements: '',
            bonus: '',
            jobs: [{ ...initialJobPosition }],
          }}
          validationSchema={Yup.object({
            name: Yup.object().shape({
              label: Yup.string().required(t('name')),
              value: Yup.string().required(t('name')),
            }),

            jobs: Yup.array().of(
              Yup.object().shape({
                place: Yup.object().shape({
                  address: Yup.string().required(t('place')),
                }),
                date: Yup.number()
                  .typeError(t('date.type'))
                  .required(t('date.required'))
                  .min(new Date(Date.now() - 86400000), t('date.minDate'))

                  .test('date', t('jobdatevalidation'), (value, fields) => {
                    const jobDate = fields.parent.date;
                    const eventExpirationDate = parseInt(
                      moment(showNewJobModal.eventExpirationDate).format('x'),
                    );
                    if (eventExpirationDate && eventExpirationDate > jobDate) {
                      return false;
                    } else {
                      return true;
                    }
                    // if false then error visible
                  }),

                start: Yup.date()
                  .test('start', t('startTime.addJob'), (value, fields) => {
                    if (!fields.parent.date) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .required(t('startTime.required'))
                  .test('start', t('startTime.duration'), (value, fields) => {
                    return (
                      manageStartEndTime(
                        moment(value).format('x') * 1,
                        fields.parent.date,
                      ) > new Date().valueOf()
                    );
                  }),
                end: Yup.date()
                  .required(t('endTime.required'))
                  .test('same', t('startendtimesameerror'), (value, fields) => {
                    let startDate = parseInt(
                      moment(fields.parent.start).format('x'),
                    );
                    let endDate = parseInt(
                      moment(fields.parent.end).format('x'),
                    );
                    if (
                      moment(startDate).format('HH:mm') ===
                      moment(endDate).format('HH:mm')
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  })
                  .test('end', t('endTime.duration'), (value, fields) => {
                    const hoursDiff = getTimeDifferent(
                      fields.parent.start,
                      fields.parent.end,
                    );
                    return parseFloat(hoursDiff.toFixed(2)) <= 12;
                  }),
                // .required(t('endTime.required')),
              }),
            ),
          })}
          onSubmit={async (values, actions) => {
            const { name, requirements, description, bonus } = values;
            // if (!jobContract.toString('markdown')) {
            //   actions.setFieldError('jobContract', 'Contract is required');
            //   return;
            // }
            // setJobLoading(true);
            const newValues = values.jobs.map((job) => {
              const jobStart = job.start;
              const jobEnd = job.end;
              const endTime =
                parseInt(moment(jobEnd).format('x')) <
                parseInt(moment(jobStart).format('x'))
                  ? parseInt(moment(jobEnd).format('x')) + 86400000
                  : parseInt(moment(jobEnd).format('x'));
              const jobDate = job.date;
              const jobPrice = parseFloat(Number(job?.price).toFixed(2)) || 0;
              const jobTravelCost =
                parseFloat(Number(job?.travelCosts).toFixed(2)) || 0;
              const employerDataJobPrice =
                parseFloat(Number(job.employerData?.jobPrice).toFixed(2)) || 0;
              const employerDataJobTravelCost =
                parseFloat(
                  Number(job.employerData?.jobTravelCost).toFixed(2),
                ) || 0;
              const tempValues = {
                jobStart: manageStartEndTime(jobStart, jobDate),
                jobEnd: manageStartEndTime(endTime, jobDate),
                jobDate: jobDate,
                jobPlace: job.place.value,
                jobPlaceAddress: job.place,
                jobPrice,
                jobTravelCost,
                jobTotalCost: jobPrice + jobTravelCost,
                hourlyRate: job.hourlyRate,
                employerData: {
                  ...job.employerData,
                  jobPrice: employerDataJobPrice,
                  jobTravelCost: employerDataJobTravelCost
                    ? employerDataJobTravelCost
                    : jobTravelCost,
                  jobTotalCost:
                    employerDataJobPrice + employerDataJobTravelCost,
                },
              };
              return {
                ...tempValues,
                jobEnd:
                  tempValues.jobStart > tempValues.jobEnd
                    ? tempValues.jobEnd + 86400000
                    : tempValues.jobEnd,
              };
            });
            const staffTypeId = staffType.find((s) => s.name === name.value)._id;
            const newJob = {
              jobName: name.value,
              staffType: staffTypeId,
              jobDescription: description,
              // jobContract: jobContract.toString('markdown'),
              jobRequirements: requirements,
              jobBonus: bonus.value,
              eventName: showNewJobModal.eventName,
              companyName: user.employer ? user.employer?.companyName : '',
              companyId: user.employer ? user.employer?._id : '',
              eventID: showNewJobModal.eventID,
              eventExpirationDate: showNewJobModal.eventExpirationDate,
              id: showNewJobModal._id,
              jobPositions: newValues,
            };
            // console.log("newJob;=", newJob);
            dispatch(
              createJob({
                payload: newJob,
                callback: () => {
                  setShowNewJobModal({
                    ...showNewJobModal,
                    visible: false,
                    id: '',
                    eventID: '',
                  });
                  setJobLoading(false);
                },
                failCallback: () => {
                  setJobLoading(false);
                },
              }),
            );
          }}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form className=" form form-login">
              <div className="job-list__header">
                <Row>
                  <Col sm="4" xl="3">
                    <CustomSelect
                      label={`${t('personal_requirements')} *`}
                      name="name"
                      options={jobPositions}
                      handleChange={(value) => {
                        setFieldValue('name', value);
                      }}
                      onBlur={(event) => {
                        setFieldTouched(`name`, true);
                      }}
                      touched={touched.name}
                      errors={errors.name && errors.name.label}
                      value={values.name}
                      classNamePrefix="job-select c-select"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="6" xl="5">
                    <label className="form-label text-left">
                      {t('requestOverview.description')}
                    </label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={values.description}
                      onChange={(e) => {
                        setFieldValue('description', e);
                      }}
                      placeholder={t(
                        'requestOverview.description.placeholder',
                      )}
                    />
                  </Col>

                  <Col sm="6" xl="5">
                    <label className="form-label text-left">
                      {t('requestOverview.staffRequirement')}
                    </label>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={values.requirements}
                      onChange={(e) => {
                        setFieldValue('requirements', e);
                      }}
                      placeholder={t(
                        'requestOverview.staffRequirement.placeholder',
                      )}
                    />
                  </Col>

                  <Col sm="4" xl="2">
                    <CustomSelect
                      label={t('requestOverview.bonus')}
                      name="bonus"
                      options={bonusOptions}
                      classNamePrefix="c-select"
                      handleChange={(value) => {
                        setFieldValue('bonus', value);
                      }}
                      touched={touched.bonus}
                      errors={errors.bonus && errors.bonus.label}
                      value={values.bonus}
                      tooltipTitle={t('requestOverview.bonus')}
                      tooltipText={t('requestOverview.modal.bonusTooltip')}
                    />
                  </Col>
                </Row>
              </div>

              <JobPositionForm
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                places={places}
                jobName={values?.name?.value}
                jobBonus={values?.bonus?.value}
                setFieldTouched={setFieldTouched}
              />

              <div className="form-bottom">
                <Button
                  type="button"
                  label={t('requestOverview.modal.cancel')}
                  onClick={() => {
                    setShowNewJobModal({
                      ...showNewJobModal,
                      visible: false,
                    });
                  }}
                  className="btn btn--outline"
                />
                <Button
                  disabled={jobLoading}
                  type="submit"
                  label={t('requestOverview.modal.add')}
                  className="btn"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default NewJob;
