import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import shortid from 'shortid';
import { useTable, useSortBy } from 'react-table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container, Spinner } from 'react-bootstrap';
import Head from '../components/Head';
import { getMyJobs } from '../actions/jobAction';
import { labelRoles } from '../utils/constants';
import 'moment/locale/de';

const Table = ({ columns, data }) => {
  const history = useHistory();
  // React.useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // }, []);
  const getRow = (id) => {
    const row = data.filter((r) => r.id === id);
    return row[0];
  };
  const onJobRowClick = (row, dataEventId) => {
    if (row.cells[6].value === 'BOOKED' || row.cells[6].value === 'Gebucht') {
      const location = {
        pathname: `/${labelRoles.employee}/job-details/${row.original.id}/${dataEventId}`,
      };
      history.push(location);
    } else {
      const location = {
        pathname: `/${labelRoles.employee}/job-details-applied/${row.original.id}/${dataEventId}`,
        state: {
          job: getRow(row.original.id),
          fromMyJob: true,
        },
      };
      history.push(location);
    }
  };
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        disableSortRemove: true,
        // initialState: {
        //   sortBy: [
        //     {
        //       id: 'date',
        //       desc: true,
        //     },
        //     {
        //       id: 'start',
        //       desc: true,
        //     },
        //   ],
        // },
      },
      useSortBy,
    );
  const { t, i18n } = useTranslation();

  return (
    <div className="table-responsive">
      <table
        className="table remove-space jobs-table"
        {...getTableProps()}
        key={shortid.generate()}>
        <thead key={shortid.generate()}>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={shortid.generate()}
              className="d-none d-md-table-row">
              {headerGroup.headers.map((column) => (
                <>
                  {column.id !== 'eventName' &&
                  // column.id !== 'start' &&
                  column.id !== 'end' &&
                  column.id !== 'briefingsRead' &&
                  column.id !== 'contract' ? (
                    <th
                      {...column.getHeaderProps()}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${
                        column.id === 'jobPosition' ? 'jobs-position' : ''
                      } ${column.id === 'place' ? 'jobs-address' : ''}`}>
                      {column.render('Header')}

                      <span
                        className={`${
                          column.isSorted
                            ? column.isSortedDesc
                              ? 'sort-arrow sort-arrow-descending'
                              : 'sort-arrow sort-arrow-accending'
                            : 'sort-arrow'
                        }`}></span>
                    </th>
                  ) : (
                    <th
                      {...column.getHeaderProps()}
                      className={`${
                        column.id === 'jobPosition' ? 'jobs-position' : ''
                      } ${column.id === 'place' ? 'jobs-address' : ''}`}>
                      {column.render('Header')}
                    </th>
                  )}
                </>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              key={shortid.generate()}
              className="d-md-none mobile-row">
              <th key={shortid.generate()}>
                {headerGroup.headers.map((column) => (
                  <>
                    {(column.id === 'eventName' ||
                      column.id === 'place' ||
                      column.id === 'jobPosition') && (
                      <>
                        <span>{column.render('Header')}</span>
                      </>
                    )}
                  </>
                ))}
              </th>
              <th key={shortid.generate()}>
                {headerGroup.headers.map((column) => (
                  <>
                    {(column.id === 'date' ||
                      column.id === 'start' ||
                      column.id === 'end') && (
                      <>
                        <span>{column.render('Header')}</span>
                      </>
                    )}
                  </>
                ))}
              </th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} key={shortid.generate()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={shortid.generate()}
                className="jobRow d-none d-md-table-row"
                onClick={() => onJobRowClick(row, row?.original.eventID)}>
                {row.cells.map((cell) => {
                  if (cell.column.id === 'jobPosition') {
                    return (
                      <td {...cell.getCellProps()} className="jobs-position">
                        {/* <Link className="link"
                          to={`/${labelRoles.employee}/job-details/${row.original.id}?eventID=${data[i].eventID}`}
                          > */}
                        {cell.render('Cell')}
                        {/* </Link> */}
                      </td>
                    );
                  } else if (cell.column.id === 'eventName') {
                    return (
                      <td {...cell.getCellProps()} className="jobs-event-name">
                        {cell.render('Cell')}
                      </td>
                    );
                  } else if (cell.column.id === 'placeAddress') {
                    return (
                      <td {...cell.getCellProps()} className="jobs-address">
                        <Link to="/" className="link">
                          {cell.render('Cell')}
                        </Link>
                      </td>
                    );
                  } else if (cell.column.id === 'date') {
                    return (
                      <td {...cell.getCellProps()} className="jobs-date">
                        {cell.render('Cell')}
                      </td>
                    );
                  } else if (cell.column.id === 'status') {
                    return (
                      <td {...cell.getCellProps()} className="jobs-status">
                        {cell.render('Cell')}
                      </td>
                    );
                  } else if (cell.column.id === 'briefingsRead') {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="jobs-briefings-read">
                        {cell.render('Cell')}
                      </td>
                    );
                  } else if (cell.column.id === 'contract') {
                    return (
                      <td {...cell.getCellProps()} className="jobs-contract">
                        <span
                          className={`text-dark in-progress w-100 text-center bg-opacity-25 ${
                            cell.value === `${t('myJob.applied')}` ||
                            cell.value === `${t('myJob.rejected')}`
                              ? 'bg-danger'
                              : cell.value ===
                                  `${t('myJob.bookingFixedConfirmNow')}` ||
                                cell.value ===
                                  `${t('myJob.contractnotsubmitted')}`
                              ? 'bg-warning'
                              : ''
                          }`}>
                          {cell.render('Cell')}
                        </span>
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>
                        <span>{cell.render('Cell')}</span>
                      </td>
                    );
                  }
                })}
              </tr>
            );
          })}
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="d-md-none mobile-row"
                onClick={() => onJobRowClick(row, row?.original.eventID)}>
                <td>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        {(cell.column.id === 'jobPosition' ||
                          cell.column.id === 'place' ||
                          cell.column.id === 'eventName') && (
                          <>
                            <span>{cell.value}</span>
                          </>
                        )}
                      </>
                    );
                  })}
                </td>
                <td>
                  {row.cells.map((cell) => {
                    return (
                      <>
                        {(cell.column.id === 'date' ||
                          cell.column.id === 'start' ||
                          cell.column.id === 'end' ||
                          cell.column.id === 'contract') && (
                          <>
                            <span
                              style={{
                                fontWeight:
                                  cell.column.id === 'contract' ? 'bolder' : '',
                              }}>
                              {cell.value}
                            </span>
                          </>
                        )}
                      </>
                    );
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const MyJobs = () => {
  const jobsData = useSelector((state) => state.jobs);
  const userData = useSelector((state) => state.user);
  const {myJobs} = jobsData;
  const {currPage, pageCount, jobs} = myJobs;
  const dispatch = useDispatch();
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  moment.locale(localStorage.getItem('i18nextLng') ?? 'de');
  const location = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: -100,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      getMyJobs({
        currPage: 1,
        callback: () => {
          setLoadMoreLoading(false);
          setLoading(false);
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = React.useMemo(() => {
    return [
      {
        Header: () => {
          return `${t('profile.jobPosition')}`;
        },
        accessor: 'jobPosition',
      },
      {
        Header: () => {
          return `${t('event.name.staff')}`;
        },
        accessor: 'eventName',
      },
      {
        Header: () => {
          return `${t('placeplaceholder')}`;
        },
        accessor: 'place',
      },
      {
        Header: () => {
          return `${t('dateLabel')}`;
        },
        accessor: 'date',
        sortType: (a, b) => {
          const timeA =
            moment(a.values.date, 'dddd DD.MM.yyyy').format('x') * 1;
          const timeB =
            moment(b.values.date, 'dddd DD.MM.yyyy').format('x') * 1;
          return timeA - timeB;
        },
      },
      {
        Header: () => {
          return `${t('startLabel')}`;
        },
        accessor: 'start',
        sortType: (a, b) => {
          const hours_a = moment(a.values.start, 'HH:mm').hours();
          const minute_a = moment(a.values.start, 'HH:mm').minutes();

          const hours_b = moment(b.values.start, 'HH:mm').hours();
          const minute_b = moment(b.values.start, 'HH:mm').minutes();
          let timeA = moment(a.values.date, 'dddd DD.MM.yyyy')
            .set('hour', hours_a)
            .set('minute', minute_a);
          let timeB = moment(b.values.date, 'dddd DD.MM.yyyy')
            .set('hour', hours_b)
            .set('minute', minute_b);

          timeA = moment(timeA, 'dddd DD.MM.yyyy').format('x') * 1;
          timeB = moment(timeB, 'dddd DD.MM.yyyy').format('x') * 1;
          return timeA - timeB;
        },
      },
      {
        Header: () => {
          return `${t('endLabel')}`;
        },
        accessor: 'end',
      },
      {
        Header: () => {
          return `${t('event.status')}`;
        },
        accessor: 'status',
      },
      {
        Header: () => {
          return `${t('requestOverview.briefingsRead')}`;
        },
        accessor: 'briefingsRead',
      },
      {
        Header: () => {
          return `${t('myjobsStaff.contractTitle')}`;
        },
        accessor: 'contract',
      },
    ];
  }, []);


  const renderTable = () => {
    const filteredJobs = jobs.filter((job) => {
      if (job && job?.jobPosition) {
        return true;
      }
    });
    if (!jobs.length) {
      return <h5>{t('jobWall.noJobsFound')}</h5>;
    } else {
      let newJobs = jobs.map((job) => {
        return {
          eventID: job.eventID,
          id: job.id ? job.id : '',
          jobPosition: job.jobName,
          eventName: job.eventName,
          place: job.jobPlace ? job.jobPlace : '',
          date: moment(
            job.jobDate ? job.jobDate : new Date().valueOf(),
          ).format('dddd DD.MM.YYYY'),
          start: moment(
            job.confirmJobStart || job.jobStart || new Date().valueOf()
          ).format('HH:mm'),
          end: moment(
            job.confirmJobEnd || job.jobEnd || new Date().valueOf()
          ).format('HH:mm'),
          contract: t(job.contract),
          status: job.status ? t('requestOverview.booked') : t('applied'),
          briefingsRead: job.briefingsRead,
        };
      });
      return (
        <>
          <Table columns={columns} data={newJobs}/>

          {currPage >= pageCount ? (
            <></>
          ) : (
            <>
              {loadMoreLoading ? (
                <div className="d-flex align-items-center justify-content-center py-4">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                      {t('event.loading')}
                    </span>
                  </Spinner>
                </div>
              ) : (
                <div className="load-more">
                  <span
                    className="load-more-link"
                    onClick={() => {
                      setLoadMoreLoading(true);
                      dispatch(
                        getMyJobs({
                          currPage: currPage + 1,
                          callback: () => setLoadMoreLoading(false),
                        }),
                      );
                    }}>
                    {t('loadMore')}
                  </span>
                </div>
              )}
            </>
          )}
        </>
      );
    }
  };

  // const data = React.useMemo(() => {
  //   return [
  //     {
  //       jobPosition: 'Hostess m/f',
  //       eventName: 'h&m Garden Party',
  //       place: 'London | Shoreditch Gardens, Garden AtriumKingsland Rd, E2 8EA',
  //       date: 'Sunday 12.09.2021',
  //       start: '10:00',
  //       end: '15:30',
  //       status: 'BOOKED',
  //       briefingsRead: '2/2',
  //       contract: 'Pending',
  //     },
  //   ];
  // }, []);

  return (
    <section className="content-section">
      <Head title={t('title.myJobs')}/>
      <Container>
        <div className="box-wrapper">
          {loading ? (
            <div className="d-flex align-items-center justify-content-center py-4">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('event.loading')}</span>
              </Spinner>
            </div>
          ) : (
            <>
              {jobs && jobs.length ? (
                <>{renderTable(jobs)}</>
              ) : (
                <div className="d-flex  py-4">
                  <h5>{t('jobWall.noJobsFound')}</h5>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </section>
  );
}
export default MyJobs;
