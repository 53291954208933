import React, { useState, useContext, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import GlobalContext from '../../context/GlobalContext';
// import ReCAPTCHA from 'react-google-recaptcha';
import Input from '../Core/Input';
import Checkbox from '../Core/Checkbox';
import Button from '../Core/Button';
import { useFormik } from 'formik';
import { gtag_report_conversion } from '../../googleAnalytics';
import * as Yup from 'yup';
import {
  roles,
  ALPHABETS_ONLY,
  NUMBER_ONLY,
  EMAIL_VALIDATION,
} from '../../utils/constants';
import { signup } from '../../actions/authAction';
import { useTranslation } from 'react-i18next';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';

const EmployerRegister = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { showEmployerRegisterModal, setShowEmployerRegisterModal } =
    useContext(GlobalContext);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      captcha: '',
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(t('FirstName')),
      lastName: Yup.string().required(t('LastName')),
      captcha: Yup.string().required(t('captcha')),
      email: Yup.string()
        .matches(EMAIL_VALIDATION, {
          message: t('Email.type'),
        })
        .required(t('Email.required')),
      phone: Yup.number()
        .typeError(t('Phone.type'))
        .positive(t('Phone.type'))
        .integer(t('Phone.type'))
        .required(t('Phone.required'))
        .test(
          'maxDigits',
          t('Phone.length9'),
          (number) => String(number).length >= 9,
        ),
      acceptTerms: Yup.bool().oneOf([true], t('acceptTerms')),
    }),
    onSubmit: async (values) => {
      if (validateCaptcha(values.captcha) === true) {
        setFormSubmitting(true);
        gtag_report_conversion();

        dispatch(
          signup({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            role: roles.employer,
            callback: () => setStep(2),
            setSubmitting: () => {
              setFormSubmitting(false);
            },
          }),
        );
      } else {
        document.getElementById('wrongCaptcha').innerHTML = t('wrongCaptcha');
      }
      // setFormSubmitting(true);
      // dispatch(
      //   signup({
      //     firstName: values.firstName,
      //     lastName: values.lastName,
      //     email: values.email,
      //     phone: values.phone,
      //     role: roles.employer,
      //     callback: () => setStep(2),
      //     setSubmitting: () => {
      //       setFormSubmitting(false);
      //     },
      //   }),
      // );
    },
  });

  // const onChange = (value) => {
  //   formik.setFieldValue('captcha', value);
  // };
  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  return (
    <Modal
      show={showEmployerRegisterModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={`${step === 2 && 'thank-you-modal'}`}
      onHide={() => {
        setShowEmployerRegisterModal(false);
        history.push('/');
      }}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>{t('home.regLabelcompany')}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === 1 && (
          <form
            onSubmit={formik.handleSubmit}
            className="form form-first-registration">
            <Row>
              <Col md="6">
                <Input
                  type="text"
                  label={`${t('fnameStaff')} *`}
                  placeholder={t('fnameStaff')}
                  name="firstName"
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    if (
                      e.target.value === '' ||
                      (ALPHABETS_ONLY.test(e.target.value) &&
                        e.target.value?.length <= 30)
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.firstName}
                  errors={formik.errors.firstName}
                  value={formik.values.firstName}
                />
              </Col>
              <Col md="6">
                <Input
                  type="text"
                  label={`${t('lnameStaff')} *`}
                  placeholder={t('lnameStaff')}
                  name="lastName"
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    if (
                      e.target.value === '' ||
                      (ALPHABETS_ONLY.test(e.target.value) &&
                        e.target.value?.length <= 50)
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.lastName}
                  errors={formik.errors.lastName}
                  value={formik.values.lastName}
                />
              </Col>
              <Col md="6">
                <Input
                  type="email"
                  label={`${t('emailContact')} *`}
                  placeholder={t('emailContact')}
                  name="email"
                  onChange={(e) => {
                    if (e.target.value === '' || e.target.value?.length <= 60) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                  value={formik.values.email}
                />
              </Col>
              <Col md="6">
                <Input
                  type="text"
                  label={`${t('phone')} *`}
                  placeholder={t('phoneContactplaceholder')}
                  name="phone"
                  onChange={(e) => {
                    if (e.target.value === '' || e.target.value?.length <= 20) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  touched={formik.touched.phone}
                  errors={formik.errors.phone}
                  value={formik.values.phone}
                />
              </Col>
              <Col md="6">
                <div className="form-group">
                  <LoadCanvasTemplate />
                  <Input
                    type="text"
                    placeholder="Enter captcha"
                    name="captcha"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    touched={formik.touched.captcha}
                    errors={formik.errors.captcha}
                    value={formik.values.captcha}
                  />
                  {formik.values.captcha === '' ? (
                    ''
                  ) : (
                    <div
                      id="wrongCaptcha"
                      className="form-errors"
                      style={{ marginTop: '-50px' }}></div>
                  )}

                  {/*<ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={onChange}
                    hl="de"
                  />
                  {formik.touched.captcha && formik.errors.captcha && (
                    <div className="form-errors">{formik.errors.captcha}</div>
                  )}*/}
                </div>
              </Col>
              <Col md="6">
                <Checkbox
                  onChange={() => {
                    formik.setFieldValue(
                      'acceptTerms',
                      !formik.values.acceptTerms,
                    );
                  }}
                  touched={formik.touched.acceptTerms}
                  errors={formik.errors.acceptTerms}>
                  <p>
                    {t('regi.regTerms1')}{' '}
                    <Link
                      to="/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer">
                      {t('home.regTerms2')}
                    </Link>{' '}
                    &{' '}
                    <Link
                      to="/privacy"
                      target="_blank"
                      rel="noopener noreferrer">
                      {t('home.regTerms3')}
                    </Link>{' '}
                    *
                  </p>
                </Checkbox>
              </Col>
            </Row>

            <p>{formik.isSubmitting}</p>
            <Row>
              {/*<Col>
                <p>
                  {t('regi.already')}{' '}
                  <Link
                    to="/"
                    onClick={() => (
                      setShowLoginModal({ ...showLoginModal, visible: true }),
                      setShowEmployerRegisterModal(false)
                    )}>
                    Login
                  </Link>
                </p>
                    </Col>*/}
              <Col>
                <div className="form-bottom justify-content-end">
                  <Button
                    type="submit"
                    label={t('register')}
                    className="btn primary-btn"
                    disabled={formSubmitting}
                  />
                </div>
              </Col>
            </Row>
          </form>
        )}
        {step === 2 && (
          <div className="thank-you-msg">
            <p className="text-uppercase">{t('thankforregistration')}</p>
            <p className="small-text">{t('wesentyoumail')}</p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EmployerRegister;
