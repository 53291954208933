import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Head from '../components/Head';
import Checkbox from '../components/Core/Checkbox';
import Button from '../components/Core/Button';
import StaffForm from '../components/Staff/StaffForm';
import EmployeeImageModal from '../components/Register/EmployeeImages';
import ConfirmationModal from '../components/Modal/ConfirmationModal';
import { setPasswordConfirmError } from '../components/Profile/EmployerProfile';
import { useTranslation } from 'react-i18next';
import api from '../utils/api';
import {
  hairColorOptions,
  sizeOptions,
  genderOptions,
  shoeSizeOptions,
  jobRegions,
} from '../utils/constants';
import {
  getNewValues,
  getStaffValidationSchema,
} from '../components/Staff/staffFormik';
import { getUnsignedURL } from '../utils/helper';
import { updateMe } from '../actions/authAction';
import { getActiveContract } from '../actions/contractAction';
import GlobalContext from '../context/GlobalContext';
import StaffMedia from '../components/Staff/StaffMedia';
import Loader from '../components/Core/Loader';
import InfoImageSaveModal from '../components/Modal/InfoImageSaveModal';
import { updatedDiff } from 'deep-object-diff';

const getSelectValue = (arr, val) => {
  let observed;
  arr.map((el) => {
    if (el.value === val) {
      observed = { ...el };
    }
    return false;
  });

  return observed;
};

const getSelectValueSize = (arr, val) => {
  let observed;
  arr.map((el) => {
    if (el?.options) {
      el?.options?.map((subEl) => {
        if (subEl.value === val) {
          observed = { ...subEl };
        }
        return subEl;
      });
    } else if (el.value === val) {
      observed = { ...el };
    }
    return el;
  });
  return observed;
};

const getArraySelectValues = (optionsArr, valArr) => {
  const zipped = [];
  if (!valArr || !optionsArr) {
    return zipped;
  }

  for (let i = 0; i < valArr.length; i++) {
    for (let j = 0; j < optionsArr.length; j++) {
      if (valArr[i].toLowerCase() === optionsArr[j].value.toLowerCase()) {
        zipped.push({
          label: optionsArr[j].label,
          value: optionsArr[j].value,
        });
        break;
      }
    }
  }

  return zipped;
};

const getImages = (images) => {
  return images.map((image) => `${image}`);
};

const getPreferredJobRegion = (employee) => {
  if (employee && Object.values(employee) && employee.preferedJobRegion) {
    if (typeof employee.preferedJobRegion === 'object') {
      return [...employee.preferedJobRegion];
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const MAX_STAFF_IMAGES = 10;

const EmployeeRegistration = () => {
  const formRef = useRef();
  const location = useLocation();
  React.useEffect(() => {
    document.documentElement.scrollTo({
      top: -120,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);

  const user = userData.data;
  const { employee } = user;
  const { t } = useTranslation();
  const {
    jobPositions,
    jobPositionsLoading,
    countriesOptions,
    languagesOptions,
    showEmployeeImageModal,
    setShowEmployeeImageModal,
    showInfoImageSaveModal,
    setInfoImageSaveModal,
    showImageConfirmationModal,
  } = useContext(GlobalContext);
  const [files, setFiles] = useState(
    user.employee && user.employee.images
      ? getImages(user.employee.images)
      : [],
  );

  const [staffImages, setStaffImages] = useState([]);
  const [recordState, setRecordState] = useState(null);
  const [profileLoading, setProfileLoading] = useState(false);
  const [audio, setAudio] = useState(
    user.employee && user.employee.audio && user.employee.audio !== 'null'
      ? `${user.employee.audio}`
      : null,
  );
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [preferredRegions, setPreferredRegions] = useState(
    getPreferredJobRegion(employee),
  );
  const [preferredRegionsError, setPreferredRegionsError] = useState(null);
  useEffect(() => {
    dispatch(getActiveContract());
  }, []);

  useEffect(() => {
    async function fetchImagesForStaff() {
      setProfileLoading(true);
      const imagedata = await Promise.all(
        files.map(async (file, id) => {
          if (typeof file === 'string') {
            const response = await api.post('users/getImageUrl', {
              image: file,
            });
            return {
              id: id,
              image: response.data.image,
            };
          }
          return {
            id: id,
            image: file,
          };
        }),
      );
      setStaffImages(imagedata);
      setProfileLoading(false);
    }

    if (formSubmitted) {
      setFormSubmitted(false);
    }
    fetchImagesForStaff();
  }, [files]);

  const renderpdfcontract = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'ActiveContract.pdf');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  const getContactUrl = async () => {
    await api
      .get('contracts/getActiveContract')
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(res.data.contracturl[0].data).buffer]),
        );
        renderpdfcontract(url);
      })
      .catch((error) => {
        toast.error(`${error.response.data.message}`);
      });
  };

  if (userData.loading) {
    return <Loader />;
  }

  const scrollToElement = (elName, isName = true) => {
    let el = '';
    if (isName) {
      el = document.querySelector(`input[name='${elName}']`);
    } else {
      el = document.querySelector(`label[for='${elName}']`);
    }
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };
  const renderAgreementBlock = ({
    employee,
    values,
    errors,
    touched,
    setFieldValue,
  }) => {
    if (!employee || !employee.acceptTerms) {
      return (
        <>
          <div className="employee-registration-note d-flex justify-content-end align-items-start">
            <div className="employee-registration-note__icon">
              <img
                src="data:image/svg+xml,%3Csvg%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2070%2070%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M32.96%2012.867c.89-1.6%203.19-1.6%204.08%200l23.333%2042c.864%201.555-.26%203.466-2.04%203.466H11.667c-1.78%200-2.904-1.91-2.04-3.466l23.333-42zM35%2018.805L15.632%2053.667h38.736L35%2018.805zm-2.333%2011.528a2.333%202.333%200%20114.666%200v9.334a2.333%202.333%200%2011-4.666%200v-9.334zm0%2016.334a2.333%202.333%200%20114.666%200V49a2.333%202.333%200%2011-4.666%200v-2.333z%22%2F%3E%3C%2Fdefs%3E%3Cg%20fill%3D%22none%22%20fillRule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M0%200h70v70H0z%22%2F%3E%3Cmask%20id%3D%22b%22%20fill%3D%22%23fff%22%3E%3Cuse%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fmask%3E%3Cuse%20fill%3D%22%23000%22%20fillRule%3D%22nonzero%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%3Cg%20fill%3D%22%2341A9C7%22%20mask%3D%22url(%23b)%22%3E%3Cpath%20d%3D%22M0%200h70v70H0z%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                alt=""
                width="70"
                height="70"
              />
            </div>
            <div className="employee-registration-note__content">
              <p className="text-uppercase mb-2">
                <strong>{t('note')}</strong>
              </p>
              <p className="small-text">{t('registration.note')}</p>
            </div>
          </div>
          <Checkbox
            value={values.acceptTerms}
            touched={touched.acceptTerms}
            errors={errors.acceptTerms}
            onChange={() => setFieldValue('acceptTerms', !values.acceptTerms)}>
            <p>
              {t('home.regTerms1')}{' '}
              <span
                role="button"
                className="text-info cursor-pointer"
                onClick={() => {
                  getContactUrl();
                }}>
                {t('home.basiccontracttext')}
              </span>
              {' ' + t('home.basiccontractendtext')}
            </p>
          </Checkbox>
        </>
      );
    } else {
      return (
        <p>
          {t('home.contracttext1')}{' '}
          <span
            role="button"
            className="text-info cursor-pointer"
            onClick={() => {
              getContactUrl();
            }}>
            {t('home.basiccontracttext')}
          </span>
          {' ' + t('home.basiccontractendtext')}
        </p>
      );
    }
  };

  const { firstName, lastName, phone, email } = user;
  const {
    addressLineOne,
    addressLineTwo,
    acceptTerms,
    blackPantsuit,
    blackSkirtBlazer,
    carAvailable,
    city,
    country,
    dateOfBirth,
    driversLicense,
    foreignLanguages,
    gender,
    hairColor,
    jobExperience,
    motherTongue,
    nationality,
    piercings,
    postalCode,
    preferedJobRegion,
    preferedJobs,
    previousJobs,
    shoeSize,
    size,
    visibleTattoos,
    weight,
    height,
  } = employee && Object.values(employee) ? employee : {};

  const oldUserData = {
    firstName,
    lastName,
    email,
    phone,
    gender: getSelectValue(genderOptions, gender),
    nationality: getSelectValue(countriesOptions, nationality),
    hairColor: getSelectValue(hairColorOptions, hairColor),
    dateOfBirth: dateOfBirth
      ? moment(dateOfBirth).toDate('dd.MM.yyyy')
      : new Date(),
    height: height ? height.replace('cm', '') : height,
    weight: weight ? weight.replace('kg', '') : weight,
    country: getSelectValue(countriesOptions, country),
    postalCode,
    motherTongue: getSelectValue(languagesOptions, motherTongue),
    foreignLanguages: getArraySelectValues(languagesOptions, foreignLanguages),
    size: getSelectValueSize(sizeOptions, size),
    shoeSize: getSelectValue(shoeSizeOptions, shoeSize),
    city,
    addressLineOne,
    profileId: user._id ? user._id.toUpperCase() : '',
    addressLineTwo,
    jobExperience,
    preferedJobRegion: getArraySelectValues(jobRegions, preferedJobRegion),
    preferedJobs: getArraySelectValues(jobPositions, preferedJobs),
    previousJobs: getArraySelectValues(jobPositions, previousJobs),
    piercings,
    visibleTattoos,
    driversLicense,
    carAvailable,
    blackPantsuit,
    blackSkirtBlazer,
    acceptTerms:
      acceptTerms === true || acceptTerms === false ? acceptTerms : false,
    password: '',
    passwordConfirm: '',
  };

  if (jobPositionsLoading || !jobPositions.length) {
    return <Loader />;
  }

  return (
    <>
      {showImageConfirmationModal.visible && (
        <ConfirmationModal files={files} setFiles={setFiles} />
      )}
      {profileLoading && <Loader />}
      {showEmployeeImageModal && (
        <EmployeeImageModal
          files={files}
          binaryImages={staffImages}
          setFiles={setFiles}
          maxFiles={MAX_STAFF_IMAGES}
          showEmployeeImageModal={showEmployeeImageModal}
          setShowEmployeeImageModal={setShowEmployeeImageModal}
        />
      )}
      <section className="content-section employee-registration-section">
        <Head title={`${t('profile')} | Superstaff`} />
        <Container>
          <Formik
            innerRef={formRef}
            initialValues={{
              firstName,
              lastName,
              email,
              phone,
              gender: getSelectValue(genderOptions, gender),
              nationality: getSelectValue(countriesOptions, nationality),
              hairColor: getSelectValue(hairColorOptions, hairColor),
              dateOfBirth: dateOfBirth
                ? moment(dateOfBirth).toDate('dd.MM.yyyy')
                : new Date(),
              height: height ? height.replace('cm', '') : height,
              weight: weight ? weight.replace('kg', '') : weight,
              country: getSelectValue(countriesOptions, country),
              postalCode,
              motherTongue: getSelectValue(languagesOptions, motherTongue),
              foreignLanguages: getArraySelectValues(
                languagesOptions,
                foreignLanguages,
              ),
              size: getSelectValueSize(sizeOptions, size),
              shoeSize: getSelectValue(shoeSizeOptions, shoeSize),
              city,
              addressLineOne,
              profileId: user._id ? user._id.toUpperCase() : '',
              addressLineTwo,
              jobExperience,
              preferedJobRegion: getArraySelectValues(
                jobRegions,
                preferedJobRegion,
              ),
              preferedJobs: getArraySelectValues(jobPositions, preferedJobs),
              previousJobs: getArraySelectValues(jobPositions, previousJobs),
              piercings,
              visibleTattoos,
              driversLicense,
              carAvailable,
              blackPantsuit,
              blackSkirtBlazer,
              acceptTerms:
                acceptTerms === true || acceptTerms === false
                  ? acceptTerms
                  : false,
              password: '',
              passwordConfirm: '',
            }}
            validationSchema={Yup.object({
              ...getStaffValidationSchema(),
              password: Yup.string().matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])(?=.{8,})/,
                t('Password.length'),
              ),
              passwordConfirm: Yup.string().when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref('password')],
                  t('confirmpassword.match'),
                ),
              }),
            })}
            onSubmit={(formValues, actions) => {
              setFormSubmitted(true);
              const values = { ...formValues };

              const diffObj = updatedDiff(oldUserData, values);
              diffObj.foreignLanguages = values.foreignLanguages;
              diffObj.preferedJobs = values.preferedJobs;
              diffObj.previousJobs = values.previousJobs;
              diffObj.preferedJobRegion = values.preferedJobRegion;
              diffObj.acceptTerms = values.acceptTerms;
              diffObj.firstName = values.firstName;
              diffObj.lastName = values.lastName;

              const passwordError = setPasswordConfirmError(
                diffObj.password,
                diffObj.passwordConfirm,
                true,
                actions.setFieldError,
              );
              if (passwordError) {
                setFormSubmitted(false);
                return;
              } else {
                if (!diffObj.password) {
                  delete diffObj.password;
                }
                delete diffObj.passwordConfirm;
                let newValues = getNewValues({
                  ...diffObj,
                  profileImage: files ? files[0] : undefined,
                  images: files.slice(1),
                  audio:
                    typeof audio === 'string' &&
                    audio.includes(process.env.REACT_APP_GCS_BUCKET)
                      ? getUnsignedURL(audio)
                      : audio,
                });
                Object.keys(newValues).forEach((key) =>
                  newValues[key] === undefined ||
                  (key === 'dateOfBirth' && isNaN(newValues[key])) ||
                  newValues[key] === ''
                    ? delete newValues[key]
                    : {},
                );
                let formData = new FormData();
                Object.entries(newValues).map(([key, value]) => {
                  if (key === 'images') {
                    for (const image of value) {
                      formData.append(key, image);
                    }
                  } else {
                    formData.append(key, value);
                  }
                  return true;
                });
                dispatch(
                  updateMe({
                    formData,
                    callback: (res) => {
                      if (res?.status === false) {
                        toast.error(res?.error);
                      }
                      setFormSubmitted(false);
                      if (res?.user?.data?.data?.user) {
                        setFiles(res?.user?.data?.data?.user?.employee?.images);
                      }
                    },
                    message: t('employerProfile.userUpdatedSuccessfully'),
                  }),
                );
              }
            }}
            enableReinitialze={true}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
            }) => (
              <div>
                {showInfoImageSaveModal && (
                  <InfoImageSaveModal
                    showInfoImageSaveModal={showInfoImageSaveModal}
                    setInfoImageSaveModal={setInfoImageSaveModal}
                    handleSubmitForm={handleSubmit}
                  />
                )}
                <Form className="form" onSubmit={handleSubmit}>
                  <div className="box-wrapper--inner">
                    <StaffMedia
                      files={files}
                      setFiles={setFiles}
                      audio={audio}
                      formSubmitted={formSubmitted}
                      binaryImages={staffImages}
                    />
                    <StaffForm
                      title="Profile"
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      showPassword={true}
                      preferedRegions={preferredRegions}
                      setPreferedRegions={setPreferredRegions}
                      preferedRegionsError={preferredRegionsError}
                      setPreferedRegionsError={setPreferredRegionsError}
                      employee={employee}
                      formikFormRef={formRef}
                    />
                  </div>
                  <Row className="justify-content-end">
                    <Col md="6" xl="4">
                      <div className="d-flex justify-content-end form-bottom">
                        {renderAgreementBlock({
                          employee,
                          values,
                          errors,
                          touched,
                          setFieldValue,
                        })}
                        <div className="form-group form-action-wrapper justify-content-end">
                          <Button
                            type="submit"
                            label={t('save')}
                            className="btn primary-btn"
                            disabled={
                              !Object.values(errors).length && formSubmitted
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                              setTimeout(() => {
                                let lowestTopValue = window.innerHeight;
                                let elementWithLowestTopValue = null;
                                var allErrors =
                                  document.getElementsByClassName(
                                    'form-errors',
                                  );
                                var allErrorsArray = [...allErrors];
                                const filteredErrors = allErrorsArray.filter(
                                  (element) => {
                                    return (
                                      element.textContent.trim().length !== 0
                                    );
                                  },
                                );
                                for (
                                  let i = 0;
                                  i < filteredErrors.length;
                                  i++
                                ) {
                                  var topValue =
                                    filteredErrors[i].getBoundingClientRect()
                                      .top;
                                  if (topValue < lowestTopValue) {
                                    lowestTopValue = topValue;
                                    elementWithLowestTopValue =
                                      filteredErrors[i];
                                    elementWithLowestTopValue.scrollIntoView({
                                      behavior: 'smooth',
                                      block: 'center',
                                      inline: 'start',
                                    });
                                  }
                                }
                              }, 100);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </Formik>
        </Container>
      </section>
    </>
  );
};

export default EmployeeRegistration;
