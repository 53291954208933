import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../Core/Button';
import { useDropzone } from 'react-dropzone';
import i18n from '../../i18n';
import { toast } from 'react-toastify';
import rotateIcon from '../../assets/images/icons/rotate.svg';
import Checkbox from './Checkbox';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { v4 as uuidv4 } from 'uuid';
import GlobalContext from '../../context/GlobalContext';

const removeItem = {
  position: 'absolute',
  top: '-11px',
  right: '-7px',
};

const Close = () => (
  <svg viewBox="0 0 16 15" width="16" height="15">
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="7" r="7" fill="#A1A1AA" />
      <g fill="#FFF" transform="rotate(45 3.517 9.857)">
        <rect width="1.333" height="9.333" x="4" rx=".667" />
        <path d="M9.333 4.667a.667.667 0 01-.666.666h-8A.667.667 0 11.667 4h8c.368 0 .666.298.666.667z" />
      </g>
    </g>
  </svg>
);

const ImageThumb = ({ file, id, onRemove, onSelect, active }) => (
  <div
    className={
      active
        ? 'modal-image-thumb modal-image-thumb--active'
        : 'modal-image-thumb'
    }
    onClick={() => onSelect(id)}>
    <img src={file} className="modal-image-thumb__image" alt="" />
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRemove(id);
      }}
      className="delete-image"
      style={removeItem}>
      <Close />
    </div>
  </div>
);

const FileUpload = ({
  binaryImages,
  type,
  label,
  value,
  onBlur,
  onChange,
  touched,
  className,
  errors,
  placeholder,
  name,
  handleSubmit,
}) => {
  const cropperRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [useAsProfileImage, setUseAsProfileImage] = useState(false);
  const [_rotation, setRotation] = useState(0);
  const [images, setImages] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 2097152,
    onDrop: (acceptedFiles) => {
      const newFile = acceptedFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
        type: 'toBeUploaded',
        id: uuidv4(),
      }));
      setImages((prev) => {
        const set = [...prev, ...newFile];
        if (set.length) {
          setSelectedFile(set[set.length - 1]);
        }
        return set;
      });
    },
    onDropRejected: (reject) => {
      if (reject[0].errors[0].code === 'file-invalid-type') {
        toast.error(i18n.t('imageModel.uploadTypeError'));
      }
      if (reject[0].errors[0].code === 'file-too-large') {
        toast.error(i18n.t('imageModel.uploadSizeError'));
      }
    },
  });

  const resetCropper = () => {
    setRotation(0);
    setSelectedFile(null);
    setUseAsProfileImage(false);
  };

  const remove = (file) => {
    const newFiles = [...images]; // make a var for the new array
    newFiles.splice(file, 1); // remove the file from the array
    setImages(newFiles); // update the state
    resetCropper();
  };

  useEffect(() => {
    const imgs = (binaryImages || []).map((file) => {
      file.type = 'existing';
      if (typeof file.image === 'string') {
        file.preview = file.image;
      } else {
        file.preview = window.URL.createObjectURL(file.image);
      }
      file.modified = false;
      file.id = uuidv4();
      return file;
    });
    setImages((images) => {
      const toUpload = images.filter((img) => img.type !== 'existing');
      const set = [...imgs, ...toUpload];
      setImages(set);
      if (set.length) {
        setSelectedFile(set[0]);
        setUseAsProfileImage(true);
      }
    });
  }, [binaryImages]);

  const moveImageToFirstPos = (selectedFile) => {
    // move the selected image to the first position using the id field to find it in images array
    const newFiles = [...images]; // make a var for the new array
    const index = newFiles.findIndex((file) => file.id === selectedFile.id);
    newFiles.splice(index, 1); // remove the file from the array
    newFiles.unshift(selectedFile); // add the file to the first position
    setImages(newFiles); // update the state
  };

  const saveCroppedImage = (selectedFile) => {
    const cropper = cropperRef.current?.cropper;
    const opts =
      cropper.getCropBoxData().width > cropper.getCropBoxData().height
        ? { maxWidth: 500 }
        : { maxHeight: 500 };
    cropper.getCroppedCanvas(opts).toBlob((blob) => {
      const croppedFile = {
        image: new File([blob], selectedFile?.image?.name ?? uuidv4),
        preview: URL.createObjectURL(blob),
        type: "toBeUploaded",
        id: uuidv4(),
      };
      let newFiles = useAsProfileImage ? [croppedFile, ...images] : [...images, croppedFile];
      newFiles = newFiles.filter(file => file.id !== selectedFile.id);
      setImages(newFiles);
      resetCropper();
    });
  };

  const rotate = (ev, deg) => {
    ev.preventDefault();
    ev.stopPropagation();
    setRotation((rot) => {
      let result = rot + deg;
      if (result < -270 || result > 270) {
        result = 0;
      }
      cropperRef.current?.cropper.rotateTo(result);
      return result;
    });
  };

  const selectFile = (file) => {
    setSelectedFile(file);
    const img = document.createElement('img');
    img.src = file.preview;
  };

  const { showEmployeeImageModal, setShowEmployeeImageModal } =
    useContext(GlobalContext);

  return (
    <>
      <div
        className={`form-group ${touched && errors ? 'form-group-errors' : ''}`}
        {...getRootProps({ className: 'dropzone' })}>
        {label && <label className="form-label">{label}</label>}
        <div className="file-upload-btn-wrapper">
          <div className="file-upload-content">
            <div className="file-upload-icon"></div>
            <div className="file-upload-text">
              <p className="text-uppercase text-bold mb-4">
                {i18n.t('DragDropimage')}
                <br /> {i18n.t('hereor')}{' '}
                <span>{i18n.t('requestOverview.modal.browse')}</span>
              </p>
              <p>{i18n.t('imageModel.uploadNote')}</p>
            </div>
          </div>
          <input
            type={type}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            name={name}
            className={`form-control ${className}`}
            {...getInputProps()}
          />
        </div>
        {touched && errors && <div className="form-errors">{errors}</div>}
      </div>
      {images.length > 0 && (
        <div className="modal-image-thumb-text">
          {i18n.t('imageModel.selectImageEdit')}
        </div>
      )}
      <div className="modal-image-thumb-wrap">
        {images.map((file, id) => (
          <ImageThumb
            key={id}
            file={file.preview}
            id={id}
            active={selectedFile && selectedFile.id === file.id}
            onRemove={remove}
            onSelect={() => {
              setUseAsProfileImage(file.id === images[0].id);
              selectFile(file);
            }}
          />
        ))}
      </div>
      {selectedFile && (
        <div className="modal-image-edit-section">
          <div className="modal-image-edit-section-cropper">
            <Cropper
              src={selectedFile.preview}
              ref={cropperRef}
              style={{ height: 300, width: 300 }}
              autoCropArea={1}
            />
          </div>
          <div>
            <div className="modal-image-edit-section-small-text">
              {i18n.t('imageModel.rotateImage')}
            </div>
            <div className="modal-image-rotate-section">
              <button
                className="button-unstyled"
                onClick={(ev) => rotate(ev, -90)}>
                <img src={rotateIcon} alt="" />
              </button>
              <button
                className="button-unstyled"
                onClick={(ev) => rotate(ev, 90)}>
                <img
                  src={rotateIcon}
                  alt=""
                  className="modal-image-edit-section-flipped"
                />
              </button>
            </div>
            <Checkbox
              checked={useAsProfileImage}
              onChange={() => setUseAsProfileImage(true)}>
              {i18n.t('imageModel.useAsProfileImage')}
            </Checkbox>
            <Button
              label={i18n.t('imageModel.save')}
              className="btn primary-btn"
              onClick={(e) => {
                e.preventDefault();
                saveCroppedImage(selectedFile);
              }}
            />
          </div>
        </div>
      )}
      <div className="form-bottom justify-content-end">
        <Button
          label={i18n.t('imageModel.cancelSave')}
          className="btn btn-red-outline"
          onClick={(e) => {
            e.preventDefault();
            setShowEmployeeImageModal(false);
          }}
        />
        <Button
          label={i18n.t('staffImageModal.saveImages')}
          className="btn primary-btn"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(images);
          }}
        />
      </div>
    </>
  );
};

export default FileUpload;
