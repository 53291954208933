export function gtag_report_conversion(url) {

  let send_to = 'AW-16937453852/6NU2CKP-0awaEJyStIw_';
  if (url === '/contact') {
    send_to = 'AW-16937453852/N0kwCOPq4qwaEJyStIw_';
  }
  window.gtag('event', 'conversion', {
    send_to: send_to,
  });
  return false;
}
