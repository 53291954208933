import { Field, FieldArray } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { de, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getTimeDifferent,
  printPriceValue,
  showExactTime,
} from '../../utils/helper';
import { initialJobPosition } from '../JobPositionForm';
import SearchLocationInput from '../SearchLocationInput';
import { differenceInMinutes } from 'date-fns';

export const EndInfo = (
  <Popover>
    <Popover.Body>
      <h5>Maximum working time exceeded</h5>
      <p>
        Due to regulatory reasons working time must not exceed 10 hours. Please
        split the job position into two, if applicable.
      </p>
    </Popover.Body>
  </Popover>
);

export const RenderEndWarning = ({ start, end }) => {
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [isEndWarning, setIsEndWarning] = useState(false);

  useEffect(() => {
    let fStart = parseInt(moment(start).format('x'));
    let fEnd = parseInt(moment(end).format('x'));
    // if (fStart > fEnd) {
    //   fEnd = fEnd + 86400000;
    // }

    if (fEnd > fStart && fEnd - fStart < 14400000) {
      setShowOverlay(true);
      setIsEndWarning(true);
      setTimeout(() => {
        setIsEndWarning(false);
      }, 5000);
    } else {
      // setShowOverlay(false);
      // setIsEndWarning(false);
    }
  }, [start, end]);
  const handleEndWarningHide = () => {
    setIsEndWarning(false);
  };
  const EndWarning = (
    <Popover>
      <Popover.Body>
        <h5>{t('warning')}</h5>
        <p>{t('minimumworkinghours')}</p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleEndWarningHide();
          }}>
          {t('close')}
        </a>
      </Popover.Body>
    </Popover>
  );
  return (
    showOverlay && (
      <>
        <OverlayTrigger
          placement="top-start"
          delay={{ show: 100, hide: 400 }}
          overlay={EndWarning}
          defaultShow={true}
          show={isEndWarning}
          rootClose
          onToggle={(show) => {
            setIsEndWarning(show);
          }}>
          <svg
            viewBox="0 0 22 23"
            width="22"
            height="23"
            className="popover-icon">
            <g fill="none" transform="translate(0 .5)">
              <circle cx="11" cy="11" r="11" fill="#41A9C7" />
              <path
                fill="#FFF"
                d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293zm1.024 9.86V8.39h-2.078v8.703h2.078z"
              />
            </g>
          </svg>
        </OverlayTrigger>
      </>
    )
  );
};

export const RenderMaxWarning = ({ start, end }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [isMaxWarning, setIsMaxWarning] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    let fStart = parseInt(moment(start).format('x'));
    let fEnd = parseInt(moment(end).format('x'));
    if (fStart > fEnd) {
      fEnd = fEnd + 86400000;
    }
    if (fEnd - fStart > 36000000) {
      setShowOverlay(true);
      setIsMaxWarning(true);
      setTimeout(() => {
        setIsMaxWarning(false);
      }, 5000);
    } else {
      setShowOverlay(false);
      setIsMaxWarning(false);
    }
  }, [start, end]);
  const handleMaxWarningHide = () => {
    setIsMaxWarning(false);
  };
  const EndInfo = (
    <Popover>
      <Popover.Body>
        <h5>{t('maxworkingtimeexceeded')}</h5>
        <p>{t('splitjobtext')}</p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleMaxWarningHide();
          }}>
          {t('close')}
        </a>
      </Popover.Body>
    </Popover>
  );
  return (
    showOverlay && (
      <>
        <OverlayTrigger
          placement="top-start"
          delay={{ show: 100, hide: 400 }}
          overlay={EndInfo}
          defaultShow={false}
          show={isMaxWarning}
          onToggle={(show) => {
            setIsMaxWarning(show);
          }}>
          <svg
            width="22"
            height="23"
            xmlns="http://www.w3.org/2000/svg"
            className="popover-icon">
            <g transform="translate(0 .5)" fill="none" fillRule="evenodd">
              <circle fill="#41A9C7" cx="11" cy="11" r="11" />
              <path
                d="M11.14 7.234c.329 0 .597-.097.805-.293.209-.195.313-.47.313-.824 0-.344-.102-.616-.305-.816C11.75 5.1 11.48 5 11.141 5c-.36 0-.64.1-.84.3-.2.201-.301.473-.301.817 0 .354.104.63.313.824.208.196.484.293.828.293Zm1.024 9.86V8.39h-2.078v8.703h2.078Z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
          </svg>
        </OverlayTrigger>
      </>
    )
  );
};

registerLocale('de', de);
registerLocale('en', enUS);

const CreateJobPosition = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  places,
  index,
  jobName,
  jobBonus,
  setFieldTouched,
  validateForm,
}) => {
  const staffTypes = useSelector(
    (state) => state?.settings?.settingData?.staffTypes,
  );
  const allZipCodes = useSelector((state) => state?.zipcode?.zipcodeList);
  const { t } = useTranslation();
  const [pricePerHour, setPricePerHour] = useState(0);
  const [effectivePricePerHour, setEffectivePricePerHour] = useState(0);
  const [jobPositionTotal, setJobPositionTotal] = useState(0);
  const [bonus, setBonus] = useState(0);
  const currentlanguage = localStorage.getItem('i18nextLng') ?? 'de';

  const handleFieldChange = ({ id, name, value }) => {
    const newPositions = values.jobs[index].jobPositions.map((v, vid) => {
      const minMinutes = 240;
      if (vid === id) {
        let otherFieldToSave = {};
        let hours = 4;
        let diff = 0;
        if (name === 'start') {
          hours = getTimeDifferent(value, v.end); // getPriceByDurationHours(v.end, value, pricePerHour);
          diff = differenceInMinutes(v.end, value);
        } else if (name === 'end') {
          hours = getTimeDifferent(v.start, value); // getPriceByDurationHours(value, v.start, pricePerHour);
          diff = differenceInMinutes(value, v.start);
        } else {
          hours = getTimeDifferent(v.start, v.end);
          diff = differenceInMinutes(v.end, v.start);
        }
        hours = hours < 4 ? 4 : hours;

        const minutes = diff < minMinutes ? minMinutes : diff;

        const pricePerMinute = pricePerHour / 60;
        const pricePerMinuteEffective = effectivePricePerHour / 60;

        const jobPrice = minutes * pricePerMinute;
        const jobPriceEffective = minutes * pricePerMinuteEffective;

        const jobBonus = bonus ? parseFloat(bonus) : 0;
        const calculatedBonus = jobBonus > 0 ? (jobPrice / 100) * jobBonus : 0;
        const calculatedBonusEffective =
          jobBonus > 0 ? (jobPriceEffective / 100) * jobBonus : 0;

        const price = Number(
          minutes * pricePerMinute + calculatedBonus,
        ).toFixed(2);

        const effectivePriceBeforeRound =
          jobPriceEffective + calculatedBonusEffective;
        const effectivePrice = parseFloat(effectivePriceBeforeRound.toFixed(2));

        otherFieldToSave = {
          hourlyRate: pricePerHour,
          price: isNaN(price) ? 0 : price,
          employerData: {
            ...v.employerData,
            hours: hours,
            jobPrice: effectivePrice,
          },
        };
        return {
          ...v,
          [name]: value,
          ...otherFieldToSave,
        };
      } else {
        return { ...v };
      }
    });
    const newJobs = values.jobs.map((job, jobId) => {
      if (jobId === index) {
        return {
          ...job,
          jobPositions: newPositions,
        };
      } else {
        return {
          ...job,
        };
      }
    });
    setFieldValue('jobs', newJobs);
    validateForm();
  };

  useEffect(() => {
    setBonus(jobBonus);
    const currentStaffType = staffTypes?.find((s) => s.name === jobName);
    setPricePerHour(currentStaffType?.pricePerHour);
    setEffectivePricePerHour(currentStaffType?.effectivePricePerHour);
  }, [staffTypes, jobName, jobBonus]);

  useEffect(() => {
    const newPositions = values.jobs[index].jobPositions.map((v, vid) => {
      const minMinutes = 240;
      const diff = differenceInMinutes(v.end, v.start);
      const minutes = diff < minMinutes ? minMinutes : diff;

      const pricePerMinute = pricePerHour / 60;
      const pricePerMinuteEffective = effectivePricePerHour / 60;

      const jobPrice = minutes * pricePerMinute;
      const jobPriceEffective = minutes * pricePerMinuteEffective;

      const jobBonus = bonus ? parseFloat(bonus) : 0;
      const calculatedBonus = jobBonus > 0 ? (jobPrice / 100) * jobBonus : 0;
      const calculatedBonusEffective =
        jobBonus > 0 ? (jobPriceEffective / 100) * jobBonus : 0;

      const price = Number(minutes * pricePerMinute + calculatedBonus).toFixed(
        2,
      );

      const effectivePriceBeforeRound =
        jobPriceEffective + calculatedBonusEffective;
      const effectivePrice = parseFloat(effectivePriceBeforeRound.toFixed(2));

      return {
        ...v,
        price: isNaN(price) ? 0 : price,
        hourlyRate: pricePerHour,
        employerData: {
          ...v.employerData,
          jobPrice: effectivePrice,
          hourlyRate: effectivePricePerHour,
        },
      };
    });
    const newJobs = values.jobs.map((job, jobId) => {
      if (jobId === index) {
        return {
          ...job,
          jobPositions: newPositions,
        };
      } else {
        return {
          ...job,
        };
      }
    });
    setFieldValue('jobs', newJobs);
  }, [pricePerHour, effectivePricePerHour, bonus]);

  useEffect(() => {
    let localJobPositionTotal = 0;
    values?.jobs[index]?.jobPositions &&
      values?.jobs[index]?.jobPositions.length &&
      values?.jobs[index]?.jobPositions?.map((position, i) => {
        localJobPositionTotal +=
          parseFloat(
            Number(
              position?.employerData?.jobPrice &&
                position?.employerData?.jobPrice !== 'NaN'
                ? position?.employerData?.jobPrice
                : 0,
            ).toFixed(2),
          ) +
          parseFloat(
            Number(
              position?.employerData?.jobTravelCost &&
                position?.employerData?.jobTravelCost !== 'NaN'
                ? position?.employerData?.jobTravelCost
                : 0,
            ).toFixed(2),
          );
      });
    setJobPositionTotal(localJobPositionTotal.toFixed(2));
  }, [values]);

  return (
    <FieldArray
      name="jobPositions"
      render={(arrayHelpers) => {
        return (
          <>
            {values.jobs[index].jobPositions &&
            values.jobs[index].jobPositions.length
              ? values.jobs[index].jobPositions.map((job, positionIndex) => (
                  <React.Fragment key={`job-position-${positionIndex}`}>
                    <div key={positionIndex} className="job-list__item">
                      <Row>
                        <Col md="6" xl="5">
                          <Row>
                            <Col xs="3" sm="2" xl="2">
                              <div className="numberList">
                                {positionIndex + 1}/
                                {values.jobs[index].jobPositions.length}
                              </div>
                            </Col>
                            <Col xs="9" sm="10" xl="5">
                              <div className="form-group date-picker-group">
                                <label className="form-label">
                                  {`${t('dateLabel')} *`}
                                </label>
                                <DatePicker
                                  locale={currentlanguage}
                                  name={`jobs.${index}.jobPositions.${positionIndex}.date`}
                                  minDate={moment().toDate()}
                                  onBlur={(event) => {
                                    setFieldTouched(
                                      `jobs.${index}.jobPositions.${positionIndex}.date`,
                                      true,
                                    );
                                  }}
                                  selected={
                                    values.jobs &&
                                    values.jobs[index] &&
                                    values.jobs[index].jobPositions &&
                                    values.jobs[index].jobPositions[
                                      positionIndex
                                    ] &&
                                    values.jobs[index].jobPositions[
                                      positionIndex
                                    ].date
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  calendarStartDay={1}
                                  dateFormat="dd.MM.yyyy"
                                  onChange={(date) => {
                                    const time = new Date(date).valueOf();
                                    handleFieldChange({
                                      id: positionIndex,
                                      name: 'date',
                                      value: time,
                                    });
                                    setFieldTouched(
                                      `jobs.${index}.jobPositions.${positionIndex}.date`,
                                      true,
                                    );
                                  }}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  className="form-control"
                                />
                                {touched.jobs &&
                                  touched.jobs[index] &&
                                  touched.jobs[index].jobPositions &&
                                  touched.jobs[index].jobPositions[
                                    positionIndex
                                  ] &&
                                  touched.jobs[index].jobPositions[
                                    positionIndex
                                  ].date &&
                                  errors.jobs &&
                                  errors.jobs[index] &&
                                  errors.jobs[index].jobPositions &&
                                  errors.jobs[index].jobPositions[
                                    positionIndex
                                  ] &&
                                  errors.jobs[index].jobPositions[positionIndex]
                                    .date && (
                                    <div className="form-errors">
                                      {
                                        errors.jobs[index].jobPositions[
                                          positionIndex
                                        ].date
                                      }
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col sm="12" xl="5">
                              <div className="form-group">
                                <label className="form-label">
                                  {`${t('placeLabel')} *`}
                                </label>
                                {values.jobs[index].jobPositions[
                                  positionIndex
                                ] && (
                                  <Field
                                    name={`jobs.${index}.jobPositions.${positionIndex}.place`}
                                    allZipCodes={allZipCodes}
                                    travelCostFieldName={`jobs.${index}.jobPositions.${positionIndex}.jobTravelCost`}
                                    effectiveTravelCostFieldName={`jobs.${index}.jobPositions.${positionIndex}.employerData.jobTravelCost`}
                                    component={SearchLocationInput}
                                    index={index}></Field>
                                )}
                                <div className="form-errors">
                                  {touched?.jobs?.[index]?.jobPositions?.[
                                    positionIndex
                                  ]?.place &&
                                    errors?.jobs?.[index]?.jobPositions?.[
                                      positionIndex
                                    ]?.place && (
                                      <div className="form-errors">
                                        {
                                          errors?.jobs?.[index]?.jobPositions?.[
                                            positionIndex
                                          ]?.place?.address
                                        }
                                      </div>
                                    )}
                                  {/* <ErrorMessage
                                  name={`jobs.${index}.jobPositions.${index}.place.address`}
                                /> */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="8" md="6" xl="5">
                          <Row>
                            <Col xs="6" xl="4" xxl="3">
                              <div className="form-group time-picker-group">
                                <label className="form-label">
                                  {`${t('startLabel')} *`}
                                </label>
                                <DatePicker
                                  locale={currentlanguage}
                                  name={`jobs.${index}.jobPositions.${positionIndex}.start`}
                                  selected={
                                    values.jobs &&
                                    values.jobs[index] &&
                                    values.jobs[index].jobPositions &&
                                    values.jobs[index].jobPositions[
                                      positionIndex
                                    ] &&
                                    // values.jobs[index].jobPositions[
                                    //   positionIndex
                                    // ].start
                                    showExactTime(
                                      values.jobs[index].jobPositions[
                                        positionIndex
                                      ].start,
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onChange={(date) => {
                                    if (date === null || date === '') {
                                      date =
                                        values.jobs[index].jobPositions[
                                          positionIndex
                                        ].date;
                                    }
                                    handleFieldChange({
                                      id: positionIndex,
                                      name: 'start',
                                      value: date,
                                    });
                                    setFieldTouched(
                                      `jobs.${index}.jobPositions.${positionIndex}.start`,
                                      true,
                                    );
                                  }}
                                  showTimeSelectOnly
                                  timeCaption={t('startTimeLabel')}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  dateFormat="HH:mm"
                                  className="form-control"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  onBlur={(event) => {
                                    setFieldTouched(
                                      `jobs.${index}.jobPositions.${positionIndex}.start`,
                                      true,
                                    );
                                  }}
                                />
                                {touched.jobs &&
                                  touched.jobs[index] &&
                                  touched.jobs[index].jobPositions &&
                                  touched.jobs[index].jobPositions[
                                    positionIndex
                                  ] &&
                                  touched.jobs[index].jobPositions[
                                    positionIndex
                                  ].start &&
                                  errors.jobs &&
                                  errors.jobs[index] &&
                                  errors.jobs[index].jobPositions &&
                                  errors.jobs[index].jobPositions[
                                    positionIndex
                                  ] &&
                                  errors.jobs[index].jobPositions[positionIndex]
                                    .start && (
                                    <div className="form-errors">
                                      {
                                        errors.jobs[index].jobPositions[
                                          positionIndex
                                        ].start
                                      }
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs="6" xl="4" xxl="3">
                              <div className="form-group time-picker-group time-picker-group-end">
                                <label className="form-label form-label-end-time">
                                  <span>{`${t('endLabel')} *`}</span>
                                  <>
                                    {values.jobs &&
                                      values.jobs[index] &&
                                      values.jobs[index].jobPositions &&
                                      values.jobs[index].jobPositions[
                                        positionIndex
                                      ] &&
                                      values.jobs[index].jobPositions[
                                        positionIndex
                                      ].start &&
                                      values.jobs[index].jobPositions[
                                        positionIndex
                                      ].end && (
                                        <RenderEndWarning
                                          start={
                                            values.jobs[index].jobPositions[
                                              positionIndex
                                            ].start
                                          }
                                          end={
                                            values.jobs[index].jobPositions[
                                              positionIndex
                                            ].end
                                          }
                                        />
                                      )}
                                  </>
                                </label>
                                <DatePicker
                                  locale={currentlanguage}
                                  name={`jobs.${index}.jobPositions.${positionIndex}.end`}
                                  selected={
                                    values.jobs &&
                                    values.jobs[index] &&
                                    values.jobs[index].jobPositions &&
                                    values.jobs[index].jobPositions[
                                      positionIndex
                                    ] &&
                                    // values.jobs[index].jobPositions[
                                    //   positionIndex
                                    // ].end
                                    showExactTime(
                                      values.jobs[index].jobPositions[
                                        positionIndex
                                      ].end,
                                    )
                                  }
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  onChange={(date) => {
                                    if (date === null || date === '') {
                                      date =
                                        values.jobs[index].jobPositions[
                                          positionIndex
                                        ].date;
                                    }
                                    handleFieldChange({
                                      id: positionIndex,
                                      name: 'end',
                                      value: date,
                                    });
                                    setFieldTouched(
                                      `jobs.${index}.jobPositions.${positionIndex}.end`,
                                      true,
                                    );
                                  }}
                                  showTimeSelectOnly
                                  timeCaption="End Time"
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  timeIntervals={15}
                                  dateFormat="HH:mm"
                                  className="form-control"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  onBlur={(event) => {
                                    setFieldTouched(
                                      `jobs.${index}.jobPositions.${positionIndex}.end`,
                                      true,
                                    );
                                  }}
                                />
                                {touched.jobs &&
                                  touched.jobs[index] &&
                                  touched.jobs[index].jobPositions &&
                                  touched.jobs[index].jobPositions[
                                    positionIndex
                                  ] &&
                                  touched.jobs[index].jobPositions[
                                    positionIndex
                                  ].end &&
                                  errors.jobs &&
                                  errors.jobs[index] &&
                                  errors.jobs[index].jobPositions &&
                                  errors.jobs[index].jobPositions[
                                    positionIndex
                                  ] &&
                                  errors.jobs[index].jobPositions[positionIndex]
                                    .end && (
                                    <div className="form-errors">
                                      {
                                        errors.jobs[index].jobPositions[
                                          positionIndex
                                        ].end
                                      }
                                      {errors.jobs[index].jobPositions[
                                        positionIndex
                                      ].end.includes('10h') && (
                                        <RenderMaxWarning
                                          start={
                                            values.jobs[index].jobPositions[
                                              positionIndex
                                            ].start
                                          }
                                          end={
                                            values.jobs[index].jobPositions[
                                              positionIndex
                                            ].end
                                          }
                                        />
                                      )}
                                    </div>
                                  )}
                              </div>
                            </Col>
                            <Col xs="6" xl="2" xxl="3">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('requestOverview.price')}
                                </label>
                                <div className="fields-text">
                                  €{' '}
                                  {!isNaN(
                                    values.jobs[index].jobPositions[
                                      positionIndex
                                    ]?.employerData?.jobPrice,
                                  )
                                    ? printPriceValue(
                                        values.jobs[index].jobPositions[
                                          positionIndex
                                        ]?.employerData?.jobPrice,
                                      )
                                    : !isNaN(
                                        values.jobs[index].jobPositions[
                                          positionIndex
                                        ]?.price,
                                      )
                                    ? values.jobs[index].jobPositions[
                                        positionIndex
                                      ]?.price
                                    : 0}
                                </div>
                              </div>
                            </Col>
                            <Col xs="6" xl="2" xxl="3">
                              <div className="form-group">
                                <label className="form-label">
                                  {/* {t('requestOverview.travelCosts')}*/}
                                  {t('requestOverview.travelCosts1')}&shy;
                                  {t('requestOverview.travelCosts2')}
                                </label>
                                <div className="fields-text">
                                  €{' '}
                                  {values.jobs[index].jobPositions[
                                    positionIndex
                                  ].employerData?.jobTravelCost
                                    ? printPriceValue(
                                        values.jobs[index].jobPositions[
                                          positionIndex
                                        ].employerData?.jobTravelCost,
                                      )
                                    : values.jobs[index].jobPositions[
                                        positionIndex
                                      ].travelCosts
                                    ? printPriceValue(
                                        values.jobs[index].jobPositions[
                                          positionIndex
                                        ].travelCosts,
                                      )
                                    : 0}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>

                        <Col sm="4" md="12" xl="2">
                          <Row>
                            <Col
                              xs="6"
                              sm="12"
                              md="6"
                              className="text-sm-center">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('requestOverview.modal.duplicate')}
                                </label>
                                <div
                                  className="field-action-icon"
                                  onClick={() => {
                                    const jobs = values.jobs.map(
                                      (job, jobId) => {
                                        if (jobId === index) {
                                          return {
                                            ...job,
                                            jobPositions: [
                                              ...job.jobPositions,
                                              {
                                                ...values.jobs[index]
                                                  .jobPositions[positionIndex],
                                              },
                                            ],
                                          };
                                        } else {
                                          return {
                                            ...job,
                                          };
                                        }
                                      },
                                    );
                                    setFieldValue('jobs', jobs);
                                  }}>
                                  <svg
                                    width="21"
                                    height="23"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g
                                      transform="translate(.5)"
                                      fill="none"
                                      fillRule="evenodd">
                                      <rect
                                        stroke="#41A9C7"
                                        strokeWidth="2"
                                        x="1"
                                        y="5"
                                        width="13"
                                        height="17"
                                        rx="2"
                                      />
                                      <g fill="#41A9C7">
                                        <path d="M6 10h3v7H6z" />
                                        <path d="M4 15v-3h7v3z" />
                                      </g>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Col>
                            <Col
                              xs="6"
                              sm="12"
                              md="6"
                              className="text-sm-center">
                              <div className="form-group">
                                <label className="form-label">
                                  {t('delete')}
                                </label>
                                <div
                                  className="field-action-icon"
                                  onClick={() => {
                                    const jobs = values.jobs.map(
                                      (job, jobId) => {
                                        if (jobId === index) {
                                          const jobPositions =
                                            job.jobPositions.filter(
                                              (position, pId) =>
                                                pId !== positionIndex,
                                            );
                                          return {
                                            ...job,
                                            jobPositions: [...jobPositions],
                                          };
                                        } else {
                                          return {
                                            ...job,
                                          };
                                        }
                                      },
                                    );
                                    setFieldValue('jobs', jobs);
                                  }}>
                                  <svg
                                    width="19"
                                    height="22"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M6.099 6.756c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.376-.849.84-.849Zm3.33 0c.46 0 .84.38.84.849V17.79c0 .47-.38.849-.84.849a.844.844 0 0 1-.841-.849V7.605c0-.47.375-.849.84-.849Zm3.324 0c.465 0 .84.38.84.849V17.79c0 .47-.375.849-.84.849-.46 0-.841-.38-.841-.849V7.605c0-.47.381-.849.84-.849Zm2.662 13.264a.282.282 0 0 1-.28.282H3.784a.286.286 0 0 1-.28-.282V4.81h11.911v15.21ZM6.94 1.98c0-.152.129-.282.28-.282h4.412c.156 0 .28.13.28.282v1.132H6.94V1.98Zm6.653 1.132V1.98c0-1.092-.88-1.98-1.961-1.98H7.22a1.973 1.973 0 0 0-1.962 1.98v1.132H.841c-.46 0-.841.38-.841.849 0 .47.381.849.84.849h.982v15.21c0 1.092.88 1.98 1.962 1.98h11.351a1.973 1.973 0 0 0 1.962-1.98V4.81h1.054c.465 0 .84-.38.84-.85a.844.844 0 0 0-.84-.848h-4.558Z"
                                      fill="#41A9C7"
                                      fillRule="evenodd"
                                    />
                                  </svg>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                ))
              : null}
            <Row className="mb-3">
              <Col className="text-left">
                <div>{t('amountVAT')}</div>
              </Col>
              <Col className="text-right">
                <div className="total-amount">
                  €{' '}
                  {jobPositionTotal && jobPositionTotal !== 'NaN'
                    ? jobPositionTotal
                    : '0'}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-right">
                <span
                  className="link"
                  onClick={() => {
                    const jobs = values.jobs.map((job, jobId) => {
                      if (jobId === index) {
                        return {
                          ...job,
                          jobPositions: [
                            ...job.jobPositions,
                            {
                              ...initialJobPosition,
                            },
                          ],
                        };
                      } else {
                        return {
                          ...job,
                        };
                      }
                    });
                    setFieldValue('jobs', jobs);
                  }}>
                  {t('newRequestOrg.jobPosition')}
                </span>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
};

export default CreateJobPosition;
