import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from '../Core/Input';
import { IMaskInput } from 'react-imask';
import Button from '../Core/Button';
import { Row, Col } from 'react-bootstrap';
import { NUMBER_ONLY } from '../../utils/constants';
import { updateJobPosition } from '../../actions/jobAction';
import { getMe } from '../../actions/userAction';

const JobPositionDetailsForm = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [bankDetailsUploading, setBankDetailsUploading] = React.useState(false);

  const getInitialvalues = () => {
    const bookedStaff = props?.jobPosition?.bookedStaffDetails;
    const employeeData = userData?.data?.employee;
    if (props?.jobPosition.bookedStaffDetails) {
      return {
        nameOfInstitute: bookedStaff['nameOfInstitute']
          ? bookedStaff['nameOfInstitute']
          : employeeData?.nameOfInstitute ?? '',
        ssn: bookedStaff['ssn'] ? bookedStaff['ssn'] : employeeData?.ssn ?? '',
        name: bookedStaff['issuedName']
          ? bookedStaff['issuedName']
          : employeeData?.issuedName ?? '',
        iban: bookedStaff['iban']
          ? bookedStaff['iban']
          : employeeData?.iban ?? '',
        bic: bookedStaff['bic'] ? bookedStaff['bic'] : employeeData?.bic ?? '',
      };
    } else if (
      userData?.data?.employee?.issuedName ||
      userData?.data?.employee?.ssn
    ) {
      const employeeData = userData?.data?.employee;

      return {
        nameOfInstitute: employeeData?.nameOfInstitute ?? '',
        ssn: employeeData?.ssn ?? '',
        name: employeeData?.issuedName ?? '',
        iban: employeeData?.iban ?? '',
        bic: employeeData?.bic ?? '',
      };
    } else {
      return { nameOfInstitute: '', ssn: '', name: '', iban: '', bic: '' };
    }
  };

  const formik = useFormik({
    initialValues: {
      ...getInitialvalues(),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      ssn: Yup.string()
        .required(t('SSCrequired'))
        .matches(/^[A-Z0-9]*$/, t('sscnumeric'))
        .min(10, t('sss10digit'))
        .max(16, t('sss10digit')),
      name: Yup.string().required(t('issuedrequired')),
      nameOfInstitute: Yup.string().required(t('requirednameofinstitute')),
      iban: Yup.string().min(20, t('incorrectiban')).required(t('iban')),
      bic: Yup.string().required(t('bic')),
    }),
    onSubmit: (values) => {
      setBankDetailsUploading(true);
      if (props?.jobPosition) {
        const newJobPosition = {
          ...props?.jobPosition,
          contractSubmited: false,
          bookedStaffDetails: {
            nameOfInstitute: values.nameOfInstitute,
            ssn: values.ssn,
            issuedName: values.name,
            iban: values.iban,
            bic: values.bic,
          },
        };

        delete newJobPosition.applications;
        delete newJobPosition.booked;
        // before remove signed contract may be need to remove file also from server
        delete newJobPosition.signedContract;
        let formData = new FormData();

        Object.entries(newJobPosition).map((val) => {
          if (
            val[0] === 'location' ||
            val[0] === 'staffToBook' ||
            val[0] === 'bookedStaffDetails' ||
            val[0] === 'readyToBook' ||
            val[0] === 'rejectedApplicants' ||
            val[0] === 'jobPlaceAddress' ||
            val[0] === 'contractId' ||
            val[0] === 'hourlyRate' ||
            val[0] === 'employerData'
          ) {
            formData.append(val[0], JSON.stringify(val[1]));
          } else {
            formData.append(val[0], val[1]);
          }
        });
        dispatch(
          updateJobPosition({
            id: newJobPosition._id,
            payload: formData,
            callback: () => {
              setBankDetailsUploading(false);
              props?.fetchJobPosition();
              props?.scrollToSection();
              toast.success(t('myJob.contractcreated'));
              dispatch(getMe());
            },
            failCallback: () => {
              setBankDetailsUploading(false);
            },
          }),
        );
      } else {
        setBankDetailsUploading(false);
        toast.error(t('Failedupdatebank'));
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form bank-detail-form">
        <div className="form-wrapper">
          <h3>{t('jobWallStaff.socialSecurityNumber')}</h3>
          <p className="small-text">
            {t('jobWallStaff.socialSecurityNumberdesc')}
          </p>
          <div className="small-boxs">
            <Input
              type="text"
              label={`${t('jobWallStaff.socialSecurityNumber')} *`}
              placeholder={t('jobWallStaff.socialSecurityNumber')}
              name="ssn"
              formGroupClass="mb-0"
              onChange={(e) => {
                const { value } = e.target;
                const upperValue = value
                  .toUpperCase()
                  .replace(/[^A-Z0-9]/g, '');
                if (upperValue.length <= 16) {
                  formik.handleChange({
                    target: { name: 'ssn', value: upperValue },
                  });
                }
              }}
              onBlur={formik.handleBlur}
              touched={formik.touched.ssn}
              errors={formik.errors.ssn}
              value={formik.values.ssn}
            />
          </div>
        </div>
        <div className="form-wrapper">
          <h3>{t('myjobsStaff.bankTitle')}</h3>
          <p className="small-text">{t('myjobsStaff.bankDesc')}</p>
          <Row>
            <Col md="4" lg="3">
              <Input
                type="text"
                label={`${t('issued')} *`}
                placeholder={t('issued')}
                name="name"
                onChange={(e) => {
                  if (e.target.value === '' || e.target.value?.length <= 50) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                touched={formik.touched.name}
                errors={formik.errors.name}
                value={formik.values.name}
              />
            </Col>
            <Col md="4" lg="3">
              <Input
                type="text"
                label={`${t('nameofinstitute')} *`}
                placeholder={`${t('nameofinstituteplaceholder')}`}
                name="nameOfInstitute"
                onChange={(e) => {
                  if (e.target.value === '' || e.target.value?.length <= 50) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                touched={formik.touched.nameOfInstitute}
                errors={formik.errors.nameOfInstitute}
                value={formik.values.nameOfInstitute}
              />
            </Col>
            <Col md="4" lg="3">
              <label className="form-label">{`${t(
                'ibanplaceholder',
              )} *`}</label>
              <IMaskInput
                mask={'[aa]00 0000 0000 0000 0000 0000 000'}
                label={`${t('ibanplaceholder')} *`}
                unmask={false}
                name="iban"
                placeholder={t('ibanplaceholder')}
                value={`${formik.values.iban}`}
                lazy={true}
                onAccept={(value, mask) => {
                  formik.setFieldValue('iban', value.replace(/\s/g, ''));
                }}
                className={`form-control`}
              />
              {formik.touched.iban && formik.errors.iban && (
                <div className="form-errors">{formik.errors.iban}</div>
              )}
            </Col>
            <Col md="4" lg="3">
              <Input
                type="text"
                label={`${t('bicplaceholder')} *`}
                placeholder={t('bicplaceholder')}
                name="bic"
                onChange={(e) => {
                  if (e.target.value === '' || e.target.value?.length <= 20) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
                touched={formik.touched.bic}
                errors={formik.errors.bic}
                value={formik.values.bic}
              />
            </Col>
          </Row>
        </div>
        <div ref={props?.contractSection} />
        <div className="form-group text-end">
          <label className="form-label d-none d-lg-block"></label>
          <Button
            type="submit"
            label={
              props.jobPosition?.bookedStaffDetails
                ? t('myJob.updatecontract')
                : t('myJob.createContract')
            }
            disabled={bankDetailsUploading}
            className="btn primary-btn"
          />
        </div>
        <p className="form-group text-end">
          {props.jobPosition?.bookedStaffDetails
            ? t('myJob.contractReminder2')
            : t('myJob.contractReminder1')}
        </p>
      </form>
    </>
  );
};

export default JobPositionDetailsForm;
